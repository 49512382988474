/**************Buyer Auction*****************/
.custom-table {
    background-color: transparent;
}
.custom-table thead {
    background-color: #dde4f4;
}

.custom-table thead tr th {
    font-weight: normal;
}
.buyer-my-auction .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit.Mui-selected,
.buyer-payment .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit.Mui-selected {
    background-color: var(--progressLine);
    color: white;
}

.buyer-my-auction {
    margin: 2rem 1rem;
}

.auction-view {
    color: var(--progressLine);
}

.paid {
    color: #058f33;
}
.unpaid {
    color: #ff3b3b;
}
.copylinkemail {
    visibility: hidden;
}
/********************************************/

/********************Buyer Notification************************/

.buyer-notification {
    margin: 2rem 0;
}

.notification-table {
    padding: 5rem;
}

.notification-submit .primButton {
    width: 150px;
    margin: 0 auto;
}

.notification-submit .primButton .MuiButtonBase-root {
    text-transform: capitalize;
}

.buyerwathlist-grid {
    padding: 2rem;
}

.cardDetails img {
    width: 180px;
    height: 100px;
    border-radius: 5px;
}
.cardDetails {
    text-align: right;
    margin-bottom: 15px;
}

/**************************************************************/

/***********************Buyer Payment**************************/

.new-card {
    border: 1px dashed #d8d8d8;
    background-color: #fcfcfc;
    height: 180px;
    width: auto;
    border-radius: 10px;
    padding: 1.5rem 0;
}

.new-card:hover {
    cursor: pointer;
}

.saved-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    gap: 15px;
}

.saved-cards > div {
    max-width: 292px;
}

.paymt-save .primButton {
    width: 100%;
    margin: 0 auto;
}

.paymt-save .primButton button {
    padding: 6px;
    font-size: 13px;
    height: 40px;
}

.primary-card {
    background: var(--blueColor);
    margin: 0;
    color: #fff;
    width: 100%;
    height: 40px;
    padding: 6px;
    border-radius: 4px;
    line-height: 28px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
}

.saved-card-dtls {
    padding: 1rem;
}

.dashboardRt .MuiTabs-scroller .MuiTabs-indicator {
    display: none;
}

.buyer-payment .MuiTabs-scroller .MuiTabs-centered {
    padding: 24px;
    justify-content: flex-start;
}
/**************************************************************/

/****************************Buyer Profile*********************/
.buyer-profile {
    padding: 3rem;
}
.save-button-buyer-profile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-button-buyer-profile .primButton,
.cancel-basic-info .primButton {
    margin: 0 1rem;
}

.save-button-buyer-profile .primButton .MuiButtonBase-root,
.cancel-basic-info .primButton .MuiButtonBase-root {
    width: 100px;
    text-transform: capitalize;
}
.paswrd-row .input-left .customInput p,
.basic-info-row .input-left .customSelect p,
.basic-info-row .input-left .customInput p {
    text-align: left;
    color: var(--progressLine);
}

.buyer-profile h5 {
    text-align: left;
    color: var(--primColor);
    padding: 1rem 0;
}
.buyer-profile .dashboard-content-area {
    display: flex;
}
.buyer-profile .dashboard-content-area .copy-link-area .link-cpy-compo {
    text-align: left;
}

.link-refer-cpy {
    margin: 0 0.8rem;
    color: var(--primColor);
}

.link-refer-cpy:hover {
    cursor: pointer;
}
/**************************************************************/

/*************************Seller Notificatio*******************/
.dashboardRt .seller-my-auct .MuiTabs-scroller .MuiTabs-centered {
    justify-content: end;
}
.dashboardRt .seller-my-auct {
    max-width: 100%;
}
.dashboardRt .notification-table .custom-table tbody tr .notification-name .MuiFormGroup-row {
    width: max-content;
    margin: 0 auto;
}
.dashboardRt
    .notification-table
    .custom-table
    tbody
    tr
    .notification-name
    .MuiFormGroup-row
    .MuiFormControlLabel-root {
    margin: 0;
}
.dashboardRt
    .notification-table
    .custom-table
    tbody
    tr
    .notification-name
    .MuiFormGroup-row
    .MuiFormControlLabel-root
    .MuiButtonBase-root {
    padding: 0;
}

.notification-heading {
    width: 600px;
    text-align: left;
}

.watchlist-nodata {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
}

.dashboardLayout,
.invoice-wrapper {
    min-height: calc(100vh - 450px);
}

.invoice-wrapper {
    background: #e0e2e1;
    padding: 10px 0;
}

.invoice-wrapper .container {
    max-width: calc(100% - 30px);
    background: #fff;
    max-width: 830px;
}

.dashboardRt .buyer-my-auction {
    max-width: max-content;
}

.btn.bnkBtn {
    background: rgb(218, 231, 255);
    color: var(--progressLine);
    border: 1px dashed var(--progressLine);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    height: 45px;
}

@media (max-width: 1199px) {
    .dashboardLayout,
    .invoice-wrapper {
        min-height: calc(100vh - 375px);
    }
}

/**************************************************************/

/* responsive css */

@media (max-width: 900px) {
    .buyerwathlist-grid,
    .buyer-profile {
        padding: 24px;
        padding-top: 6rem;
    }
    .buyer-payment .MuiTabs-scroller .MuiTabs-centered {
        margin: 2rem 1rem;
        margin-left: 6rem;
        padding: 0;
    }
}

@media (max-width: 900px) {
    .buyer-my-auction .MuiTabs-scroller .MuiTabs-flexContainer {
        justify-content: flex-start;
        overflow: auto;
    }
    .buyer-my-auction
        .MuiTabs-scroller
        .MuiTabs-flexContainer
        .MuiTab-textColorInherit
        .MuiTab-wrapper {
        font-size: 12px;
        line-height: 1;
    }
    .buyer-my-auction
        .MuiTabs-scroller
        .MuiTabs-flexContainer
        .MuiTab-textColorInherit.Mui-selected,
    .buyer-payment .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit.Mui-selected {
        padding: 0 5px;
    }
}

@media (max-width: 767px) {
    .cusTable .table tbody td.no-found,
    .cusTable .table tbody td.no-found1 {
        align-items: center;
        justify-content: center;
        height: 300px;
    }

    .cusTable .table tbody td.no-found1 {
        height: 250px;
    }

    .dashboardRt .MuiTab-root {
        max-width: 33.3%;
    }

    .dashboardRt .buyer-payment .MuiTab-root {
        max-width: 50%;
        width: 50%;
    }

    .saved-cards {
        flex-wrap: wrap;
    }
    .new-card,
    .paymentCard {
        margin: 0;
        width: 100%;
    }
    .buyerwathlist-grid,
    .buyer-profile {
        padding: 16px;
        padding-top: 5rem;
    }
    .notification-table {
        padding: 75px 15px 15px;
    }

    .notification-table table.buyer-notification {
        margin: 0 0 2rem;
    }
    .buyer-payment .MuiTabs-scroller .MuiTabs-centered,
    .buyer-my-auction {
        margin: 1rem;
        margin-left: 5rem;
    }
    .row.paswrd-row .col-6,
    .row.basic-info-row .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row.basic-info-row .col-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .dashboardLayout .MuiBox-root {
        padding: 15px;
    }
}

@media (max-width: 740px) {
    .copy-link-area {
        width: 100%;
        word-break: break-all;
    }
}

@media (max-width: 600px) {
}

@media (max-width: 425px) {
    .buyer-profile .dashboard-content-area {
        flex-direction: column-reverse;
    }
    .table td,
    .table th {
        font-size: 13px;
        padding: 0.35rem;
    }

    .card-wrapper .MuiButton-root {
        padding: 6px 12px;
    }
}

@media (max-width: 540px) {
    .invoice-wrapper table,
    .invoice-wrapper thead,
    .invoice-wrapper tbody,
    .invoice-wrapper tr,
    .invoice-wrapper th,
    .invoice-wrapper td {
        display: block;
    }

    .invoice-wrapper tr.invoice-top {
        vertical-align: top;
        display: flex;
        flex-direction: column-reverse;
    }

    .invoice-wrapper tr.invoice-top td {
        text-align: left !important;
    }

    .invoice-wrapper tr h1 {
        margin-top: 0 !important;
    }

    .invoice-wrapper p {
        font-size: 13px !important;
        word-wrap: break-word;
    }

    .invoice-wrapper .invoice-inner-table table {
        display: flex;
        border: 1px solid #9b9b9b;
    }

    .invoice-wrapper .invoice-inner-table table > * {
        width: 50%;
    }

    .invoice-wrapper .invoice-inner-table table th,
    .invoice-wrapper .invoice-inner-table table td {
        padding: 5px !important;
        text-align: left !important;
        font-size: 13px !important;
        line-height: 1;
        min-height: 50px;
    }
}

@media (max-width: 1450px) {
    .dashboardRt .productGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
