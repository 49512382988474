.login {
    max-width: 600px;
    margin: 0 auto;
}

.login-container-area {
    background-color: var(--blueColor);
    padding: 2rem 3rem;
}
.login-user-name-label,
.login-password-label {
    text-align: left;
}
.login-remember-me
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiButtonBase-root
    .MuiIconButton-label {
    color: var(--primColor);
}

.login-remember-me
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiTypography-root
    .MuiButtonBase-root {
    color: white;
}
.login-button .login-confirm-btn {
    width: 150px;
}
.login-button .primButton .MuiButtonBase-root {
    text-transform: capitalize;
    background-color: var(--primColor) !important;
    border-color: var(--primColor) !important;
}
.login-confirm-area {
    display: flex;
    justify-content: space-between;
}

.haveAnAcc,
.frgtpswrd {
    margin: 20px 0;
    color: #fff;
}

.haveAnAcc a {
    color: #a48843;
}

.frgtpswrd a {
    color: #fff;
}

.login-forgetpswrd {
    display: flex;
    justify-content: space-between;
}

.bankFormDetails .react-datepicker-wrapper {
    width: 100%;
}

.bankFormDetails .react-datepicker-wrapper input {
    height: 56px;
    width: 100%;
    background: transparent;
    border: 1px solid var(--primColor);
    border-radius: 4px;
    color: whitesmoke;
    padding-left: 13px;
}

.bankFormDetails select {
    color: whitesmoke;
}

.rdoBxWrpr .MuiFormControlLabel-root {
    margin-bottom: 0;
}

.bankFormDetails .MuiSelect-select:not([multiple]) option,
.bankFormDetails .MuiSelect-select:not([multiple]) optgroup {
    background: var(--blueColor);
}

/***************** Responive Design *********************/

@media (max-width: 800px) {
    .login .welcome-heading {
        text-align: center;
    }
}
@media (max-width: 767px) {
    .login-forgetpswrd {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .login-forgetpswrd h6 {
        width: 100%;
        margin: 10px auto 0;
    }

    .login-forgetpswrd a,
    .login-forgetpswrd a:hover {
        text-decoration: none !important;
    }

    .MuiCheckbox-root .MuiListItem-gutters {
        padding: 0;
    }
}

@media (max-width: 500px) {
    .login-confirm-area {
        display: block;
    }
    .login-container-area {
        padding: 2rem;
    }
    .login-button .login-confirm-btn {
        margin: 0 auto;
    }
    .login-content .customInput {
        margin-bottom: 22px;
    }
}

@media (max-width: 445px) {
    .login-confirm-area {
        display: block;
    }
    .login-container-area {
        padding: 2rem;
    }
    .login-content .customInput {
        margin-bottom: 20px;
    }
    .login-button .login-confirm-btn {
        margin: 0 auto;
    }
    .login .login-container-area h4,
    .login h5 {
        font-size: 20px;
    }
    .login-forgetpswrd a {
        clear: both;
        display: inline-block;
    }
}

@media (max-width: 340px) {
    .login-confirm-area {
        display: block;
    }
    .login-container-area {
        padding: 2rem;
    }
    .login-content .customInput {
        margin-bottom: 18px;
    }
    .login-remember-me .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-body1 {
        font-size: 15px;
    }
    .login-button .login-confirm-btn {
        margin: 0 auto;
    }
    .login-content .login-user-name-label,
    .login-content .login-password-label {
        font-size: 15px;
    }
    .login-content
        .login-password
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder,
    .login-content
        .login-user-name
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 15px;
    }
    .login .login-container-area h4 {
        font-size: 19px;
    }
    .login h5 {
        font-size: 19px;
    }
}
@media (max-width: 320px) {
    .login h5 {
        font-size: 18px;
    }
    .login-confirm-area {
        display: block;
    }
    .login-container-area {
        padding: 2rem;
    }
    .login-button .login-confirm-btn {
        margin: 0 auto;
    }
    .login .login-container-area h4 {
        font-size: 18px;
    }
    .login-remember-me .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-body1 {
        font-size: 14px;
    }
    .login-content .login-user-name-label,
    .login-content .login-password-label {
        font-size: 14px;
    }
    .login-content
        .login-password
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder,
    .login-content
        .login-user-name
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .login-content .customInput {
        margin-bottom: 16px;
    }
}
