.sign-up-header {
    padding: 0 6rem;
}
.sign-up {
    /* background-image: url('../../../assets/images/sign-up-background.png'); */
    background-image: url('../../../assets/images/2.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    padding-bottom: 30px;
}

.sign-up-logo .logo-img {
    max-width: 300px;
}
.welcome-header-logo {
    text-align: right;
}
