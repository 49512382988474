.product-card {
    width: 100%;
    cursor: pointer;
    position: relative;
}

.product-card .pcImgWrapper {
    width: 100%;
    height: 260px;
    overflow: hidden;
}

.product-card .product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.product-listing {
    margin-top: 100px;
}
.listing-ending-soon .product-list-title {
    text-align: left;
    font-family: var(--larkenRegular);
    font-size: 30px;
    margin-bottom: 50px;
}

.productGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}
.dollor-icon {
    color: #1c453f;
}
.price-icon {
    color: var(--primColor);
}
.product-price p svg,
.product-time p svg {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
}
.product-price p,
.product-time p {
    margin-bottom: 0;
    font-size: 14px;
}
.product-title p {
    color: #2b3648;
    font-size: 18px;
    width: auto;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 auto 10px;
}

.product-price .product-price-dynamic,
.product-time .product-time-dynamic {
    padding: 0 1px;
    display: inline-block;
    vertical-align: middle;
    line-height: 16px;
    text-transform: uppercase;
}

.product-card .product-img {
    transition: 0.3s all ease-in-out;
}

.product-card:hover .product-img {
    transform: scale(1.1);
}

.product-card .favoriteCheck {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.product-card .noRsrvTxt {
    position: absolute;
    top: 15px;
    left: 15px;
}
.product-card .yesRsrvTxt {
    position: absolute;
    top: 15px;
    left: 15px;
}

/* responsive */

@media (max-width: 1210px) {
    .product-price .product-price-dynamic,
    .product-time .product-time-dynamic {
        font-size: 13px;
    }
}

@media (max-width: 1024px) {
    .product-card .pcImgWrapper {
        height: 200px;
    }
    .productGrid {
        gap: 10px;
    }

    .product-details {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .product-details > * {
        width: 100%;
    }

    .product-listing {
        margin-top: 40px;
    }

    .product-title p {
        font-size: 16px;
        width: auto;
        margin-bottom: 5px !important;
    }
    .product-price p .MuiSvgIcon-root,
    .product-time p .MuiSvgIcon-root {
        width: 0.8em;
    }

    .product-price .product-price-dynamic,
    .product-time .product-time-dynamic {
        padding: 0 0px;
    }
    .product-card .pcImgWrapper {
        height: 120px;
    }
}

@media (max-width: 1000px) {
    .product-price .product-price-dynamic,
    .product-time .product-time-dynamic {
        font-size: 11px;
    }
}

@media (max-width: 767px) {
    .productGrid {
        grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    }

    .product-price p,
    .product-time p {
        font-size: 12px;
    }

    .product-details > div {
        width: 100%;
    }

    .product-details > div + div {
        margin-top: 10px;
    }

    .product-card .favoriteCheck {
        width: 30px;
        height: 30px;
        top: 7px;
        right: 7px;
    }
    .favoriteCheck input:checked + label {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    .favoriteCheck input:checked + label .material-icons {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .product-listing {
        margin-top: 30px;
    }
    .listing-ending-soon .product-list-title {
        font-size: 22px;
        margin-bottom: 15px;
    }
}
@media (max-width: 475px) {
    .product-price p .MuiSvgIcon-root,
    .product-time p .MuiSvgIcon-root {
        width: 0.7em;
    }
    .product-card {
        width: 100%;
        cursor: pointer;
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 20px;
    }
}
@media (max-width: 425px) {
    .listing-ending-soon .product-list-title {
        margin-bottom: 25px;
        font-size: 20px;
    }
    .product-listing {
        margin-top: 35px;
    }
}
.product-time {
    display: flex;
}
@media (max-width: 360px) {
    .product-price p,
    .product-time p {
        font-size: 11px;
    }

    .product-price p .MuiSvgIcon-root,
    .product-time p .MuiSvgIcon-root {
        width: 13px;
    }
}
