.homepage .slick-slide img {
    width: 100%;
}

.homepage .slider-content {
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 100%;
    max-width: 622px;
    transform: translate(-50%, -50%);
}

.homepage .slider-buttons {
    display: flex;
    justify-content: center;
}

.homepage .slider-buttons .first-button .MuiButtonBase-root,
.homepage .slider-buttons .second-button .MuiButtonBase-root {
    text-transform: capitalize;
}

.homepage .slider-buttons .first-button .MuiButtonBase-root {
    background-color: var(--primColor);
    border: var(--primColor);
}

.homepage .slider-buttons .second-button .MuiButtonBase-root {
    border: 1px solid white;
    background-color: transparent;
}

.homepage .slider-content h1 {
    font-size: 50px;
}

.homepage .slider-content p {
    font-size: 18px;
}

.search-filter-area {
    display: flex;
    background-color: white;
    border-radius: 4px;
}

.search-filter-dropdown {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.search-filter {
    max-width: 700px;
    width: calc(100% - 30px);
    margin: -75px auto 0;
    z-index: 10;
    position: relative;
}

.search-filter-area .customSelect {
    width: 46%;
}

.search-filter-area .customSelect + .customSelect {
    padding-left: 8%;
    border-left: 1px solid #ededed;
}

.search-filter-area .filter-search .MuiButtonBase-root {
    background-color: #364d42;
    border: none;
}

.search-filter-area
    .customSelect
    .MuiFormControl-root
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline {
    border: none;
}

.search-filter .search-filter-main .search-filter-area .customSelect {
    margin: 0;
}

.search-filter .search-filter-main .search-filter-area .customSelect p {
    margin: 0;
    color: var(--primColor);
    font-weight: 500;
}

.dropdown-search .filter-search .MuiButtonBase-root {
    padding: 18px 15px;
    width: 175px;
    height: 75px;
    margin-left: 10px;
}

.search-filter .MuiSelect-outlined.MuiSelect-outlined {
    padding: 8px 32px 8px 0;
    border-radius: 2px;
    background: #fff;
}

.heading-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-all {
    color: var(--primColor);
    margin: 0;
}

.view-all-btn .primButton {
    max-width: 150px;
    margin: 0 auto;
}

.view-all-btn .primButton .MuiButtonBase-root {
    background-color: var(--primColor);
    border: none;
    text-transform: capitalize;
}

.view-all-btn {
    margin: 5rem;
}

.homepage.slide-content-area {
    max-width: 700px;
    margin: 0 auto;
}

.search-filter .search-filter-main .search-filter-area .customSelect p {
    min-width: 50px;
}

.acive-icons {
    color: var(--primColor);
}

.view-type-icons {
    cursor: pointer;
    font-size: 1.5rem;
    margin: 0 5px;
}

.list-sort {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ending-list-view {
    margin-top: 15px;
    margin-bottom: 15px;
}
.clear-all-search {
    text-decoration: underline;
    text-align: right;
    padding-right: 55px;
}
.clear-all-search:hover {
    cursor: pointer;
}
.staTick ul li a {
    color: black;
}

.landingModal .MuiDialogTitle-root .MuiIconButton-root:hover {
    color: #fff;
}

.landingModal .MuiDialogTitle-root .MuiIconButton-root {
    color: rgb(60 97 161);
}

.landingModal .MuiDialogTitle-root {
    position: absolute;
    right: 0;
    z-index: 999999;
    width: 100%;
    height: max-content;
    display: flex;
    height: 59px;
}

.landingModal .MuiDialogContent-root {
    border: 1px solid #ffffff52;
    margin: 5px;
    border-radius: 4px;
    padding-top: 35px;
}

.landingModal .landmodLog {
    text-align: center;
    margin-bottom: 25px;
    pointer-events: none;
}

.landingModal .landbody {
    text-align: center;
    color: #fff;
    padding: 5px 35px;
    width: 600px;
}

.landingModal strong {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

.landingModal h3 {
    margin-bottom: 25px;
    margin-top: 15px;
}

.landingModal p {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
}

.landingModal .modalLogo {
    width: 285px;
    pointer-events: none;
}

.landingModal .MuiPaper-root {
    background: var(--blueColor);
}

.MuiDialog-paperWidthXl.MuiDialog-paperScrollBody {
    transition: all 2s ease-in;
}

.landingModal .landbody .searchSpace button {
    width: 300px;
}
.landingModal .landbody .searchSpace input {
    border: 1px solid #a48843;
    margin: 0;
    text-align: center;
    width: 300px;
}
.landingModal .landbody .searchSpace {
    flex-direction: column;
    margin: 15px auto;
}
.landingModal {
    z-index: 99 !important;
}

.upcomingcard .product-card:hover .product-img {
    transform: scale(1);
}

/* responsive css */

@media (max-width: 1024px) {
    .view-all-btn {
        margin: 2rem;
    }
}

@media (max-width: 900px) {
    .view-all-btn {
        margin: 2rem;
    }

    .product-listing {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .landingModal .landbody {
        width: auto;
        max-width: 100%;
        min-width: 100%;
        padding: 5px 15px;
    }
    .clear-all-search {
        text-align: center;
        padding: 0;
    }
    .dropdown-search .filter-search .MuiButtonBase-root {
        margin-left: 0;
        height: 48px;
    }

    .homepage .slider-content {
        max-width: 450px;
    }

    .search-filter .search-filter-main .search-filter-area .customSelect {
        margin: 0;
    }

    .search-filter-area {
        flex-direction: column;
    }

    .dropdown-search .filter-search .MuiButtonBase-root {
        width: 100%;
    }

    .search-filter-dropdown {
        padding: 5px !important;
    }
}

@media (max-width: 600px) {
    .landingModal .landbody .searchSpace input,
    .landingModal .landbody .searchSpace button {
        width: 250px;
    }
    .view-type-icons {
        display: none;
    }
    .noResult {
        max-width: 40%;
    }
}

@media (max-width: 540px) {
    .homepage .slider-content h1 {
        font-size: 34px;
    }

    .homepage .slider-content p {
        font-size: 15px;
    }

    .customSelect.search-filter-year .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-input,
    .customSelect.search-filter-model
        .MuiFormControl-root.MuiTextField-root
        .MuiOutlinedInput-input,
    .customSelect.search-filter-make
        .MuiFormControl-root.MuiTextField-root
        .MuiOutlinedInput-input {
        font-size: 14px;
    }

    .search-filter .search-filter-main .search-filter-area .customSelect p {
        font-size: 15px;
    }

    .search-filter-area .customSelect {
        width: 100%;
    }

    .customSelect.search-filter-year .MuiFormControl-root.MuiTextField-root .MuiSelect-iconOutlined,
    .customSelect.search-filter-model
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-iconOutlined,
    .customSelect.search-filter-make
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-iconOutlined {
        background: white;
    }
}

@media (max-width: 475px) {
    .landingModal h1 {
        font-size: 28px;
    }
    .landingModal h3 {
        font-size: 22px;
    }
    .landingModal p {
        font-size: 12px;
    }
    .homepage .slider-content h1 {
        font-size: 18px;
    }

    .homepage .slider-content p {
        font-size: 13px;
        margin: 0;
    }

    .search-filter {
        margin-top: 0;
    }

    .search-filter-main p.light-white {
        color: #000000 !important;
    }

    .search-filter-area {
        display: flex;
        background-color: #ffffff00;
    }

    .search-filter-dropdown .search-filter-make,
    .search-filter-dropdown .search-filter-model {
        border-right: 1px solid #364d42;
    }

    .search-filter {
        width: auto;
        max-width: 100%;
        padding: 10px 16px;
        background: #364d420a;
    }
}

@media (max-width: 479px) {
    .search-filter-dropdown .search-filter-make,
    .search-filter-dropdown .search-filter-model {
        border: 0;
    }

    .homepage .slider-content {
        max-width: 100%;
        transform: translate(-50%, -0%);
        background: #00000033;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        top: unset;
        padding: 8px 5px;
        bottom: 6px;
    }

    .homepage .slider-content p {
        font-size: 12px;
    }

    .homepage .slider-content p {
        font-size: 12px;
    }

    .homepage .slick-slide img {
        height: auto;
        max-height: 250px;
        min-height: auto;
    }

    .search-filter-dropdown {
        padding: 2px 0px !important;
        flex-direction: column;
    }

    .search-filter .search-filter-main .search-filter-area .customSelect {
        margin: 0 0 10px;
        display: flex;
        align-items: center;
    }

    .search-filter-area .customSelect + .customSelect {
        padding-left: 0;
        border-left: none;
    }

    .homepage .slider-buttons .first-button .MuiButtonBase-root {
        height: 35px;
    }

    .search-filter .MuiSelect-outlined.MuiSelect-outlined {
        margin-left: 15px;
        padding: 8px 32px 8px 12px;
    }
}

@media (max-width: 375px) {
    .landingModal .modalLogo {
        width: 200px;
    }
    .landingModal .landbody {
        padding: 5px 5px;
    }
}

@media (max-width: 350px) {
    .landingModal .landbody .searchSpace input,
    .landingModal .landbody .searchSpace button {
        width: 200px;
    }
}
