/* CIRCULAR */
@font-face {
    font-family: 'circular-black';
    src: url('./fonts/circular/circular-black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'circular-black-italic';
    src: url('./fonts/circular/circular-black-italic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'circular-bold';
    src: url('./fonts/circular/circular-bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'circular-bold-italic';
    src: url('./fonts/circular/circular-bold-italic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'circular-medium';
    src: url('./fonts/circular/circular-medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'circular-medium-italic';
    src: url('./fonts/circular/circular-medium-italic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'circular-book';
    src: url('./fonts/circular/circular-book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'circular-book-italic';
    src: url('./fonts/circular/circular-book-italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

/* LARKEN */

@font-face {
    font-family: 'larken-black';
    src: url('./fonts/larken/larken-black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'larken-black-italic';
    src: url('./fonts/larken/larken-black-italic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'larken-bold';
    src: url('./fonts/larken/larken-bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'larken-bold-italic';
    src: url('./fonts/larken/larken-bold-italic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'larken-extrabold';
    src: url('./fonts/larken/larken-extrabold.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'larken-extrabold-italic';
    src: url('./fonts/larken/larken-extrabold-italic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'larken-regular';
    src: url('./fonts/larken/larken-regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'larken-italic';
    src: url('./fonts/larken/larken-italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'larken-light';
    src: url('./fonts/larken/larken-light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'larken-light-italic';
    src: url('./fonts/larken/larken-light-italic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'larken-medium';
    src: url('./fonts/larken/larken-medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'larken-medium-italic';
    src: url('./fonts/larken/larken-medium-italic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'larken-thin';
    src: url('./fonts/larken/larken-thin.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'larken-thin-italic';
    src: url('./fonts/larken/larken-thin-italic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

/* @font-face {
    font-family: 'larken-variable';
    src: url('./fonts/larken/larken-variable.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'larken-variable-italic';
    src: url('./fonts/larken/larken-variable-italic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
} */

body {
    margin: 0;
    font-family: var(--circularBook), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--backgroundColor);
    padding: 0 !important;
    overflow: auto !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

.customContainer {
    padding-left: 60px !important;
    padding-right: 60px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.App {
    width: 100% !important;
    /* max-width: 1600px; */
    margin-left: auto !important;
    margin-right: auto !important;
}

h1,
h2,
h3,
h4 {
    font-family: var(--larkenRegular);
}

h5,
h6,
p,
a {
    font-family: var(--circularRegular);
}

div:focus,
button:focus {
    outline: 0;
}

header,
footer {
    background: #fff;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.customInput,
.customSelect {
    margin-bottom: 30px;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.home .homeCnt,
.search .searchCnt {
    margin-top: 45px;
}

.home .homeLt,
.search .searchLt,
.dashboard .dashboardLt {
    width: 25%;
    margin-right: 30px;
    max-width: 350px;
}

.home .homeRt,
.search .searchRt,
.dashboard .dashboardRt {
    width: -webkit-fill-available;
    max-width: 75%;
}

.home .homeLt .searchInput .input-group-text,
.search .searchLt .searchInput .input-group-text,
.dashboard .dashboardLt .searchInput .input-group-text {
    background-color: #fff;
    border-right: none;
}

.home .homeLt .searchInput input,
.search .searchLt .searchInput input,
.dashboard .dashboardLt .searchInput input {
    border-left: none;
}

.home .homeLt .searchInput input:focus,
.search .searchLt .searchInput input:focus,
.dashboard .dashboardLt .searchInput input:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.home .sectionTitle > h2,
.search .sectionTitle h2 {
    font-size: 26px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    text-align: left;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--primColor) !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.table {
    background: #fff;
}

.table .thead-dark th {
    color: #fff;
    background-color: #2b2b2b;
    border-color: transparent;
    font-size: 15px;
    font-weight: 500;
}

.PaymentModal .modal-dialog.modal-lg {
    max-width: 400px !important;
}

.PaymentModal .helpingText {
    font-size: 18px;
    padding: 20px 0;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
}

.homeSkeletonBody,
.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 30px;
    margin-top: 30px;
}

.homeSkeletonBody .hsbGrid,
.searchSkeletonBody .ssbGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.searchSkeletonBody {
    margin: 45px 0;
}

.loginSkeletonBody {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0;
}

.loginSkeletonBody .lsbLeft {
    margin-right: 15px;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbLeft .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.avatar {
    margin: 0 auto;
}

.loginSkeletonBody .lsbLeft .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody {
    width: 600px;
    margin: 45px auto;
}

.regSkeletonBody .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody .skeleton.title {
    margin: 0 auto;
}

.checkoutSkeletonBody {
    margin: 45px 0;
}

.checkoutSkeletonBody .csbItem {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
    margin-top: 15px;
}

/* input:not(:focus)::-webkit-datetime-edit {
  color: transparent;
} */

/* input::placeholder {
  color: #000;
} */
/* 
input::-webkit-datetime-edit {
  color: #000;
} */

input[value='']::-webkit-datetime-edit {
    color: transparent;
}
input:focus::-webkit-datetime-edit {
    color: #000;
}

button .MuiButton-label {
    font-family: 'circular-book';
    font-weight: 600;
    white-space: nowrap;
}

.naBreadcrumbs.MuiTypography-root {
    margin: 15px 0;
}

.naBreadcrumbs a {
    color: #797979;
}

.slick-slider {
    cursor: grab;
}

.slick-slider:active {
    cursor: grabbing;
}

.pagination-wrapper {
    margin-top: 30px;
}

.pagination-wrapper h6 {
    margin: 0;
}

.actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.actionWrapper > button:first-child {
    margin-right: 30px;
}

.cursorDecoy {
    cursor: pointer;
}

.verifyPhone .phVer {
    display: block;
    margin: 10px 0;
}

.verifyPhone .phVerInp {
    margin-top: 25px;
}

.customModal {
    z-index: 1500 !important;
}

/* .resendSms {
    margin-right: 35px;
} */

.np-pro-box {
    padding: 25px 15px;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.np-pro-box img {
    width: 180px;
}

.np-pro-box h5 {
    margin-top: 20px;
    font-weight: 500;
}

.np-pro-box h6 {
    line-height: 24px;
    font-size: 15px;
    color: #6d6d6d;
}

.header.innerHeaderbg {
    background: var(--blueColor);
    position: relative !important;
}

.staticPageWrapper.p-5,
.prvcyPolicPage.p-5 {
    padding: 15px !important;
}

.staticPageWrapper .table,
.prvcyPolicPage .table {
    width: 100%;
    overflow: scroll;
}

.prvcyPolicPage h3 {
    margin-bottom: 30px;
}

.staticPageWrapper > p:first-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
    line-height: 1.8 !important;
}

.staticPageWrapper > p:first-child span span {
    font-size: 30px !important;
}

/* Mobile responsive css*/

@media (min-width: 1440px) {
    /* .customContainer {
        padding-left: 30px !important;
        padding-right: 30px !important;
    } */
    .home .homeLt,
    .search .searchLt,
    .dashboard .dashboardLt {
        width: 20%;
    }
    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: 80%;
    }
}

@media (max-width: 1366px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 280px;
        margin-right: 10px;
    }
}

@media (max-width: 1300px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 32%;
        margin-right: 5px;
    }
    .home .homeLt,
    .search .searchLt,
    .dashboard .dashboardLt {
        width: 32%;
    }
}

@media (max-width: 1024px) {
    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: none;
    }
    .customContainer {
        padding-left: 40px !important;
        padding-right: 40px !important;
        width: 100% !important;
    }
    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        margin-right: 10px;
    }

    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 22px !important;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .home .homeCnt,
    .search .searchCnt {
        flex-wrap: wrap;
    }

    .home .homeLt,
    .search .searchLt {
        width: 100%;
        max-width: none;
        width: 100%;
        max-width: none;
        margin-right: 0;
    }

    .deskFilter {
        display: none;
    }

    .home .hmSearch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .home .hmSearch .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .responsiveFilterDrawer .MuiCollapse-container {
        width: auto;
        min-width: 100%;
        left: 0;
        position: absolute;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        display: inline-flex !important;
        border: none;
        color: var(--primColor);
    }

    .toggleRespDrawer.MuiButtonBase-root .material-icons {
        padding-right: 10px;
    }

    .filterAcc {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin-right: 10px;
        width: 24%;
        position: static;
        margin-top: 0;
    }

    .filterPanel .filterAcc .MuiCollapse-wrapper {
        width: 100%;
    }

    .filterPanel .filterAcc .MuiAccordionSummary-root {
        border: 1px solid #d8d8d8;
        min-height: 50px;
        border-radius: 50px;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        min-height: 300px;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: rgb(174 34 7 / 8%);
        border-color: rgb(174 34 7 / 51%);
    }

    .dashboard .dashboardLt {
        display: none;
    }
    .dashboard .dashboardRt {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }

    .search .productCardGrid,
    .dashboard .productCardGrid {
        width: 49%;
    }
}

@media (max-width: 767px) {
    .MuiDialog-paperFullWidth {
        width: calc(100% - 30px) !important;
        margin: 30px auto !important;
    }

    .header.innerHeaderbg {
        position: sticky;
        top: 0;
    }
    .customContainer {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .cusTable .table tr td.border-none {
        width: calc(40vw - 40px);
        /* height: auto; */
        min-width: calc(40vw - 40px);
    }
    .Dashboard .Dashboard-Tabpanel .MuiButtonBase-root {
        width: auto;
        min-width: auto;
    }

    .Dashboard .MuiTab-wrapper,
    .Dashboard .table .thead-light th {
        font-size: 14px;
        padding: 14px 0;
        border-bottom: 1px solid #dcdcdc;
    }
    .cusTable .table tr td.border-none .card {
        margin-top: 0 !important;
    }
    .cusTable .table th,
    .cusTable .table td {
        text-align: center;
        padding: 8px;
    }
    .cusTable .table {
        /* display: block; */
        width: 100%;
    }
    .cusTable .table tbody,
    .cusTable .table thead th {
        display: block;
    }
    .cusTable .table thead th:last-child {
        border-bottom: none;
    }
    .cusTable .table thead {
        float: left;
    }
    .cusTable .table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
        max-width: calc(100vw - 80px);
    }
    .cusTable .table td,
    .cusTable .table th {
        padding: 20px 0.625em 0.625em 0.625em;
        height: 50px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: auto;
        max-width: fit-content;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .cusTable .table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
        padding: 14px 10px;
        padding-left: 10px !important;
        width: 115px;
        min-width: 115px;
        max-width: 115px;
    }
    .cusTable .table tbody tr {
        display: table-cell;
        vertical-align: text-bottom;
    }
    .cusTable .table tbody tr:nth-child(odd) {
        background: none;
    }
    .cusTable .table tr:nth-child(even) {
        background: transparent;
    }
    .cusTable .table tr td:nth-child(odd) {
        background: #0e3e872e;
        border-right: 1px solid #e6e4e4;
    }
    .cusTable .table tr td:nth-child(even) {
        border-right: 1px solid #d4dce9;
    }
    .cusTable .table tbody td {
        display: flex;
        text-align: left;
        align-items: center;
        padding: 6px !important;
        width: 175px;
        min-width: 175px;
        max-width: 175px;
        word-break: break-all;
    }
    .cusTable .table tbody td.border-none {
        display: inherit;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .homeSpecialItems {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .homeSpecialItems .specialEvent {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .specialEventLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .seActBtn {
        width: 100%;
        max-width: none;
    }
    .homeSpecialItems .specialEvent .seImg,
    .homeSpecialItems .specialEvent .seContent {
        margin-right: 0;
    }

    .homeSpecialItems .specialEvent .seImg {
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }
    .home .hmSearch .searchInput {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
    }
    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }
    .home .sectionTitle {
        margin-bottom: 20px;
    }
    .filterPanel .filterAcc .MuiAccordionSummary-root {
        min-height: 30px;
        height: 40px;
    }
    .filterPanel .accTitle {
        font-size: 14px;
    }
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }
    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        min-height: 65vh;
    }
    .productNav {
        display: none;
    }
    .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
        width: 100% !important;
    }
}

@media (max-width: 500px) {
    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 20px !important;
    }
    .specialEvent .seImg {
        max-width: none !important;
    }
    .specialEvent .seImg img {
        height: 150px !important;
    }
    .pagination-wrapper {
        margin: 20px;
    }
    .pagination-wrapper > div {
        font-size: 12px;
    }

    .pagination-wrapper h6 {
        width: 100%;
        margin-bottom: 15px;
    }

    .pagination-wrapper .MuiPagination-root {
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .filterAcc {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 10px 10px 0;
        width: 47%;
    }
    .responsiveFilterDrawer .MuiCollapse-container {
        top: 120px;
    }
}

@media (max-width: 400px) {
    .homeSpecialItems .specialEvent {
        width: 100%;
    }
    .specialEvent .seImg {
        max-width: none;
    }
    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 18px !important;
    }
    .home .sectionTitle > object {
        width: 25px;
    }
}

@media (max-width: 380px) {
    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 10px 10px 0;
        width: 45%;
    }
}

.noRsrvTxt {
    font-size: 13px;
    color: white;
    /* font-weight: 600; */
    text-transform: uppercase;
    background: var(--blueColor);
    padding: 2px 4px;
    border-radius: 4px;
    pointer-events: none;
    width: max-content;
}
.yesRsrvTxt {
    font-size: 13px;
    color: white;
    /* font-weight: 600; */
    text-transform: uppercase;
    background: #24a921;
    padding: 2px 4px;
    border-radius: 4px;
    pointer-events: none;
    width: max-content;
}
