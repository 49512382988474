.loaderWrapper {
    backdrop-filter: blur(40px);
    background: rgba(214, 235, 255, 0.39);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}
