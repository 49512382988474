.footer {
    background-color: var(--blueColor);
    padding: 40px 0 40px 0;
}
.footer-content {
    display: flex;
    justify-content: space-around;
}

.searchSpace {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.searchSpace button:hover {
    border: 1px solid var(--primColor);
    color: var(--primColor);
}
.searchSpace input {
    height: 45px;
    border-radius: 0;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    width: 335px;
    color: black;
    outline: unset;
    box-shadow: unset;
    margin-right: 20px;
    margin-top: 15px;
}
.searchSpace button {
    height: 45px;
    border-radius: 0;
    border: 1px solid var(--primColor);
    font-size: 18px;
    width: 135px;
    font-weight: 700;
    color: var(--primColor);
    margin-top: 15px;
}

.quick-links p,
.social-media p,
.contact-us p {
    font-size: 20px;
}

.quick-links ul li,
.social-media ul li,
.contact-us ul li {
    font-size: 16px;
    list-style: none;
    line-height: 40px;
}
.footer-line {
    color: var(--primColor);
}
.footer-logo .footer-logo-img {
    max-width: 250px;
}
.copy-right p {
    font-size: 14px;
}
.up-arrow {
    border: 1px solid white;
    border-radius: 50%;
    padding: 1rem;
    height: 60px;
}
.up-arrow .up-arrow-icon {
    color: white;
}
.up-arrow:hover {
    cursor: pointer;
}
.footSub {
    width: 55%;
}
.footSub .searchSpace button {
    font-size: 16px;
    width: 100px;
}
.footSub .searchSpace {
    justify-content: flex-end;
    margin-right: -90px;
}
.footSub .searchSpace input {
    width: 200px;
}
.footerRgt {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

.getEstimateBtn {
    min-width: max-content !important;
    position: fixed !important;
    max-width: 96px;
    width: 100%;
    height: 96px;
    background: #ffffff !important;
    border: 1px solid #2f87f5 !important;
    border-radius: 50% !important;
    color: #fff !important;
    bottom: 5%;
    right: 45px;
    z-index: 10;
    overflow: hidden;
}

.getEstimateBtn img {
    width: 60px;
}

.getEstimateBtn .MuiButton-label {
    max-width: 80px;
    flex-wrap: wrap;
    font-size: 10px;
    font-weight: bolder;
}

.getEstimateBtn small {
    color: #2f87f5;
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;
    white-space: pre-wrap;
    margin-bottom: 4px;
}

.getEstimateDrawer .MuiDrawer-paper {
    /* width: 400px; */
    left: initial;
    /* padding-right: 10px; */
    border-radius: 10px;
    /* border-top-right-radius: 10px; */
    background: transparent;
    background-image: url('./loader.svg');
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center;
}

.getEstimateDrawer .estCnt h3 {
    margin-bottom: 20px;
}

.getEstimateDrawer .estCnt h6 {
    font-size: 15px;
    margin-bottom: 20px;
}
.getEstimateDrawer .estCnt .customInput p {
    margin-bottom: 10px;
}

.MuiButtonBase-root.getEstimateBtn {
    min-width: 98px !important;
    min-height: 98px !important;
}

/* responsive css */

@media (max-width: 767px) {
    .up-arrow .up-arrow-icon {
        color: #173e80;
    }
    .footer-content {
        flex-direction: column;
    }
    .footerRgt {
        flex-wrap: wrap;
        width: 100%;
        padding: 0px 45px;
        padding-top: 25px;
    }
    .footer {
        padding: 20px 0 0px 0;
    }
    .up-arrow {
        z-index: 99;
        height: 34px;
        width: 34px;
        margin-left: auto;
        margin-right: 30px;
        position: fixed;
        bottom: 25px;
        right: -17px;
        background: white;
        box-shadow: 0px 0px 6px 2px #0000001a;
        padding: 3px 5px;
    }
    .copy-right p {
        font-size: 14px;
        margin: 0;
        padding-bottom: 10px;
    }

    .footSub {
        width: 100%;
    }

    .footSub .searchSpace {
        justify-content: center;
        margin-right: unset;
    }
}

@media (max-width: 600px) {
    .up-arrow {
        bottom: 30px;
        right: 30px;
        margin-right: 0;
    }
}

@media (max-width: 540px) {
    .quick-links p,
    .social-media p,
    .contact-us p {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .quick-links ul li,
    .social-media ul li,
    .contact-us ul li {
        font-size: 14px;
        line-height: 25px;
    }
    .footerRgt {
        padding: 15px;
        padding-bottom: 0;
    }
    .quick-links ul,
    .social-media ul,
    .contact-us ul {
        margin-bottom: 0;
    }

    .footerRgt > div {
        width: 100%;
        margin-bottom: 20px;
    }
}
