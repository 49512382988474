.productViewbox .productCard .productCarosal {
    flex: 1 1 50%;
}

.productViewbox .productCard {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 42% 1fr;
}

.productViewbox .productCard .productCarosal .image-gallery-slide-wrapper {
    border: 1px solid var(--grayLine);
    height: 450px;
    width: 500px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productViewbox
    .productCard
    .productCarosal
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    text-align: left;
    white-space: normal;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    grid-gap: 5px;
}

.mr-3 {
    margin-left: 1rem;
}

.productViewbox
    .productCard
    .productCarosal
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container
    .image-gallery-thumbnail {
    width: 100%;
    max-width: 120px;
}

.productViewbox .productCard .productCarosal .image-gallery-thumbnails {
    padding: 60px 0 15px;
}

.productViewbox
    .productCard
    .productCarosal
    .image-gallery-thumbnails
    button
    .image-gallery-thumbnail-inner
    img {
    width: 85px;
    height: 85px;
    object-fit: cover;
}

.productViewbox .image-gallery-thumbnail.active,
.productViewbox .image-gallery-thumbnail:hover,
.productViewbox .image-gallery-thumbnail:focus {
    border-color: var(--primColor);
}

.productViewbox
    .productCard
    .productCarosal
    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
    max-height: 450px;
    object-fit: scale-down;
}

.productViewbox .productCard .productCarosal .image-gallery-left-nav .image-gallery-svg,
.productViewbox .productCard .productCarosal .image-gallery-right-nav .image-gallery-svg {
    height: 35px;
    width: 35px;
    background: #ededeb9e;
    filter: unset;
    box-shadow: unset;
    padding: 6px;
    color: black;
}

.productViewbox .productCard .productCarosal .image-gallery-swipe {
    display: flex;
    /* align-items: center;
    height: 450px; */
}

.productViewbox
    .productCard
    .productCarosal
    button.image-gallery-thumbnail
    .image-gallery-thumbnail-inner {
    border: 1px solid var(--grayLine);
}

.productViewbox .productCard .productCarosal .photoGallery p,
.productViewbox .productCard .productInfo .titBox .essentialBox {
    font-size: 20px;
    text-align: left;
    color: #333333;
}

.essentialBox h4 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.productViewbox .productCard .productCarosal .photoGallery .span img {
    width: 100%;
    height: 100px;
    /* max-width: 150px; */
    object-fit: cover;
    cursor: pointer;
}
.productViewbox
    .productCard
    .productInfo
    .subTit
    .confirm-btn
    button
    .MuiButton-label
    .MuiSvgIcon-root {
    width: 0.8em;
    padding-left: 2px;
    height: auto;
    margin-left: 2px;
}
.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    .confirm-btn
    span
    .MuiSvgIcon-root {
    width: 0.8em;
    padding-right: 2px;
}
.productViewbox .productCard .productInfo .subTit .btnCombo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    margin-top: 15px;
}
.productViewbox .productCard .productInfo .subTit .confirm-btn button .MuiButton-label {
    font-weight: 400;
    text-transform: capitalize;
    font-size: 15px;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox .confirm-btn {
    width: fit-content;
}
.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    .confirm-btn
    .MuiButton-label {
    display: inline-flex;
    font-weight: 400;
    text-transform: capitalize;
    min-height: auto;
    font-size: 15px;
}

.productViewbox .productCard .productInfo .subTit .confirm-btn button,
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox .confirm-btn button {
    border-radius: 0;
    padding: 6px 16px;
    height: 39px;
}
.productViewbox .productCard .productInfo .titBox h4 {
    color: #333333;
}

.productViewbox .productCard .productCarosal .photoGallery .span figure {
    position: relative;
}

.productViewbox .productCard .productCarosal .photoGallery .pswp-thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    align-items: center;
    grid-gap: 6px;
    text-align: left;
}

.productViewbox .productCard .productCarosal .photoGallery {
    margin-top: 30px;
    margin-bottom: 65px;
}

.buttons-product-view {
    display: inline-flex;
}

.buttons-product-view .confirm-btn:not(:last-child) {
    margin-right: 5px;
}

.buttons-product-view .confirm-btn button .MuiButton-label {
    font-size: 12px;
}

.lastEle b::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: #00000057;
    display: block;
    z-index: -1;
}

.lastEle b {
    position: absolute;
    max-width: 150px;
    width: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    right: 0;
    z-index: 999;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
}

.lastEle {
    position: relative;
}

/* product info */
.productViewbox .productCard .productInfo .subTit small b {
    color: var(--lightGreen);
    font-weight: 400;
    padding-right: 15px;
}

.productViewbox .productCard .productInfo .subTit small {
    color: var(--grayLine);
    font-size: 14px;
    text-transform: uppercase;
}

.productViewbox .productCard .productInfo .subTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    ul
    li.biddingInformation {
    color: var(--primColor);
    cursor: pointer;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    ul
    li.biddingInformation {
    display: flex;
    align-items: center;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    ul
    li.biddingInformation
    a {
    display: inline-flex;
    align-items: center;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    ul
    li.biddingInformation
    span {
    padding: 0 5px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li.ttlViews {
    color: var(--grayLine);
    text-align: right;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.ownrShpHstry ul li {
    grid-template-columns: 1fr 1fr;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li {
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--textColor);
    line-height: 23px;
    align-items: center;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.hgLghtsFld ul li {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.hgLghtsFld .cstmHltGrd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox.hgLghtsFld
    .cstmHltGrd
    ul
    li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li label {
    padding: 10px;
    background: #ebe6e6;
    border-bottom: 1px solid white;
    height: 100%;
}

.productViewbox .productCard .productInfo .titBox .essentialBox.adtnlCstmFlds h4 {
    margin-top: 35px;
    margin-bottom: 10px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.cstmGryBx {
    background: white;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.cstmGryBx li {
    color: black;
    margin: 8px 0;
    display: list-item;
    list-style: disc;
    margin-left: 1em;
    font-size: 15px;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.cstmGryBx li::marker {
    font-size: 1.6em;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.vdoBox {
    background: white;
    padding-left: 0;
    padding-right: 0;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.vdoBox ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.vdoBox ul li {
    display: block;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.ownrShpHstry li {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 15px 0;
}

.productViewbox .productCard .productInfo .shrThLstngTxt {
    font-size: 15px;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox .ltNmbr {
    padding: 7px 0;
    margin-top: 10px;
    text-align: center;
    background: #ebe6e6;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul label {
    min-width: 90px;
    font-size: 13px;
    margin: 0;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul {
    padding: 0;
    margin: 0;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li b {
    color: var(--primColor);
    font-weight: 500;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox p {
    font-size: 14px;
    margin-bottom: 6px;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox {
    background: var(--lightGrey);
    padding: 5px 20px;
    margin-bottom: 1px;

    height: auto;
}

.productViewbox .productCard .productInfo .titBox .decCarosal {
    border-top: 1px solid var(--grayLine);
    border-bottom: 1px solid var(--grayLine);
    margin: 15px auto;
    padding: 28px 0;
}

.productViewbox .productCard .productInfo .titBox {
    padding-top: 10px;
}
.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox > span {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    min-height: 42px;
    align-items: start;
}

.comment-area .customTextArea .MuiFormControl-root {
    width: 100%;
}

.submit-area {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.submit-area .conv-mail .conv-email-txt a {
    font-size: 15px;
    color: var(--primColor);
    text-decoration: underline;
}
.bit-hist {
    margin: 3rem 0;
    overflow-y: auto;
}
.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    .noGridBtn
    .MuiButton-root
    .MuiButton-label {
    display: inherit;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
}
.noGridBtn .MuiButton-root {
    width: fit-content;
    height: 40px;
    border-radius: 0px;
}
.customTextArea .MuiOutlinedInput-root textarea {
    height: 75px !important;
    font-size: 16px;
}
.customTextArea .MuiOutlinedInput-root {
    border-radius: 4px;
    background: #ffffff;
    padding: 10px;
    font-size: 14px;
    min-height: 75px;
    height: auto;
}
.productViewSecontdry button .MuiButton-label {
    font-weight: 500;
}
.secButton.productViewSecontdry button {
    border-radius: 0;
    text-transform: capitalize;
    height: 39px;
}

.pvAct .input-boxes {
    margin-right: 10px;
}

.productViewbox
    .productCard
    .productInfo
    .titBox
    .essentialBox
    .essGraybox
    .confirm-btn
    .MuiButton-label
    i {
    font-size: 19px;
    margin-right: 5px;
}
.essentialBox .essGraybox .bid-now-btn {
    margin-top: 5px;
}
.paymentCardPopup .image-gallery-slide.center.popupSlider .image-gallery-image {
    height: 450px;
}
.diaglogcarton .MuiDialog-paperWidthSm {
    max-width: 100%;
    width: 100%;
}
.video-link-url {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 230px;
    width: 100%;
}
.video-link-tag {
    display: flex;
}
.btn-combo-list {
    display: flex;
}

.carfax-btn:hover {
    cursor: pointer;
}

.productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox table {
    width: 100%;
    font-size: 14px;
}

.dscrptinWrpp p {
    margin: 0;
}

/* responsive design */

@media (max-width: 1260px) {
    .productViewbox .productCard {
        grid-gap: 15px;
        grid-template-columns: 40% 1fr;
    }
    .productViewbox .productCard .productInfo .subTit small b {
        padding-right: 10px;
    }
}
@media (max-width: 1024px) {
    .productViewbox .productCard .productCarosal .image-gallery-slide-wrapper {
        height: 250px;
    }
}

@media (max-width: 900px) {
    .productGrid {
        grid-gap: 8px;
    }
    .productViewbox .productCard .productInfo .titBox h4 {
        margin-bottom: 1rem !important;
    }

    .productViewbox .productCard .productInfo .titBox .decCarosal p {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .productViewbox .productCard {
        grid-gap: 10px;
    }
    .productViewbox .MuiBreadcrumbs-root {
        padding-top: 20px;
        padding-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .productViewbox .productCard {
        grid-template-columns: 1fr;
    }
    .productViewbox .productCard .productCarosal .image-gallery-thumbnails {
        padding: 20px 0 15px;
    }

    .productViewbox
        .productCard
        .productCarosal
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container {
        grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    }

    .productViewbox .bid-time {
        max-width: 100%;
        text-align: left;
        margin: 0;
    }

    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox > span {
        grid-template-columns: 30% 70%;
    }

    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox > span.pv-mw100 {
        grid-template-columns: 100%;
    }

    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li.ttlViews {
        text-align: left;
    }

    .productViewbox .productCard .productInfo .subTit {
        justify-content: flex-start;
        text-align: left;
    }

    .subTit .timer {
        display: block;
        margin-top: 3px;
    }

    .productViewbox .productCard .productCarosal .photoGallery {
        margin: 5px 0;
    }

    .place-bid-wrapper .customInput {
        margin-bottom: 5px;
    }

    .pvAct.place-bid-wrapper .input-boxes {
        margin-right: 0;
        width: calc(100% - 105px);
    }

    .place-bid-wrapper .MuiOutlinedInput-input {
        padding: 12px;
        font-size: 14px;
        height: 16px;
    }
    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.hgLghtsFld ul li {
        grid-template-columns: 1fr 1fr;
    }
    .productViewbox .productCard .productInfo .titBox .essentialBox.bdngDtlsBox .essGraybox ul li {
        display: flex;
    }
    .productViewbox
        .productCard
        .productInfo
        .titBox
        .essentialBox
        .essGraybox.hgLghtsFld
        .cstmHltGrd {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
    }

    .carfax-snapshot li.row0 .text,
    .carfax-snapshot li.row1 .text {
        width: 45% !important;
    }

    .carfax-snapshot-embedded,
    #carfax-snapshot-popup {
        width: 93% !important;
    }

    .rightContainer .adWtchLst {
        margin-right: 0 !important;
    }

    .productViewbox .image-gallery-content.fullscreen {
        height: 100vh;
        display: flex;
        align-items: center;
    }
}
.productViewbox .MuiButton-root {
    text-transform: none !important;
}

@media (max-width: 540px) {
    .productViewbox .productCard {
        grid-template-columns: minmax(45%, 1fr);
    }
    .productViewbox .productCard .productInfo .subTit small {
        margin-bottom: 6px;
    }
    .productViewbox .productCard .productInfo .subTit .confirm-btn button,
    .productViewbox
        .productCard
        .productInfo
        .titBox
        .essentialBox
        .essGraybox
        .confirm-btn
        button {
        padding: 6px 12px;
    }
    .productViewbox .productCard .productInfo .titBox h4 {
        font-size: 16px;
    }
    .productGrid {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }

    .sold-for {
        display: block;
        margin-bottom: 5px;
    }
}

@media (max-width: 450px) {
    .carfax-snapshot-embedded {
        width: 100% !important;
    }
    .premiumPlus {
        margin-top: 15px;
        width: auto;
        max-width: 290px;
        overflow: auto;
    }
}

@media (max-width: 375px) {
    .bidAcordent {
        flex-direction: column;
    }
    .bid-time {
        margin: 0;
    }
    .submit-area .conv-mail .conv-email-txt a {
        font-size: 13px;
    }
    .productViewbox .productCard .productInfo .subTit .btnCombo {
        grid-template-columns: 1fr 1fr;
    }
    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox {
        padding: 5px 10px;
    }
    .productViewbox .productCard .productInfo .titBox .decCarosal p {
        font-size: 12px;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .productViewbox .productCard .productInfo .titBox .decCarosal {
        padding: 15px 0;
    }
    .productViewbox .productCard .productCarosal .photoGallery .pswp-thumbnails img {
        width: 100%;
        height: 60px;
        max-width: 100px;
    }
    .lastEle b::before {
        height: 60px;
    }
    .lastEle b {
        max-width: 100px;
    }
    .productViewbox .productCard .productCarosal .photoGallery .pswp-thumbnails {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox ul li {
        font-size: 12px;
    }

    .productViewbox
        .productCard
        .productInfo
        .titBox
        .essentialBox
        .essGraybox
        ul
        li.biddingInformation
        a
        > svg {
        height: auto;
        margin-right: 3px;
    }

    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox span {
        /* align-items: flex-start; */
    }
    .productViewbox
        .productCard
        .productInfo
        .titBox
        .essentialBox
        .essGraybox
        .confirm-btn
        .MuiButton-label,
    .productViewbox .productCard .productInfo .subTit .confirm-btn button .MuiButton-label {
        font-size: 12px;
    }
}

.ovrFlwFix.MuiDialogContent-dividers {
    padding: 0px;
}

.ovrFlwFix.MuiDialogContent-root {
    overflow-y: hidden;
}

.nwVwWrp .subTit small {
    color: var(--grayLine);
    font-size: 14px;
    text-transform: uppercase;
}

.nwVwWrp .subTit small b {
    color: var(--lightGreen);
    font-weight: 400;
    padding-right: 15px;
}

.nwVwWrp .secButton {
    width: 100%;
    margin-bottom: 10px;
}

.nwVwWrp .secButton button {
    height: 45px;
    margin-top: 10px;
}

.nwVwWrp .image-gallery-slide .image-gallery-image {
    object-fit: cover;
}

.productCardWrapper.nwVwWrp .image-gallery-left-nav .image-gallery-svg,
.productCardWrapper.nwVwWrp .image-gallery-right-nav .image-gallery-svg {
    height: 35px;
    width: 35px;
    background: rgba(237, 237, 235, 0.6196078431372549);
    -webkit-filter: unset;
    filter: unset;
    box-shadow: unset;
    padding: 6px;
    color: #000;
}

.nwVwWrp .pdtTtle {
    font-size: 30px;
}

.nwVwWrp .image-gallery-content .image-gallery-slide .image-gallery-image {
    /* height: calc(100vh - 80px) !important; */
    height: 100%;
    /* max-height: 780px !important;
    min-height: 780px !important; */
}

.productCardWrapper.nwVwWrp .image-gallery-thumbnail {
    width: 100%;
}

.productCardWrapper.nwVwWrp .image-gallery-slide-wrapper.left,
.productCardWrapper.nwVwWrp .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 210px);
}

.productCardWrapper.nwVwWrp .image-gallery-thumbnails-wrapper.left,
.productCardWrapper.nwVwWrp .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 200px;
}

.nwVwWrp .otrWrapper {
    display: flex;
    justify-content: space-between;
}

.productCardWrapper .image-gallery-content .image-gallery-fullscreen-button {
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
    z-index: 0;
}

.nwVwWrp .otrWrapper .rightContainer {
    text-align: right;
}

.titBox.nwVwWrp {
    position: sticky;
    top: 0;
    z-index: 15;
    background: white;
    padding-top: 15px;
}

.seller-comment-area {
    display: flex;
}
.seller-comment-area .img-attach {
    margin: 0 1rem;
    padding: 0.5rem 0;
    font-size: 38px;
}
.img-upload-preview {
    width: 200px;
    display: block;
}

@media (max-width: 768px) {
    .video-link-url {
        max-width: 155px;
    }

    .titBox.nwVwWrp {
        position: relative;
        z-index: 0 !important;
    }
}

@media (max-width: 600px) {
    .nwVwWrp .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 250px;
        min-height: unset;
    }

    .productCardWrapper.nwVwWrp .image-gallery-thumbnails-wrapper.left,
    .productCardWrapper.nwVwWrp .image-gallery-thumbnails-wrapper.right {
        display: none;
    }

    .productCardWrapper.nwVwWrp .image-gallery-slide-wrapper.left,
    .productCardWrapper.nwVwWrp .image-gallery-slide-wrapper.right {
        width: 100%;
    }

    /* .nwVwWrp .otrWrapper {
        flex-wrap: wrap;
        justify-content: center;
    } */

    .nwVwWrp .otrWrapper .rightContainer {
        margin-top: 25px;
        text-align: right;
    }

    .nwVwWrp .lftContainer.subTit {
        text-align: left;
    }

    .nwVwWrp .pdtTtle {
        font-size: 25px;
        /* text-align: center !important; */
        margin-top: 10px;
    }

    .nwVwWrp .carfax-btn img {
        width: 120px;
        margin-bottom: 10px;
    }

    .nwVwWrp .secButton button {
        height: max-content;
        margin-top: 0px;
        width: 120px;
    }

    .nwVwWrp .rightContainer button .MuiButton-label {
        font-family: 'circular-book';
        font-weight: 600;
        white-space: pre-wrap;
        font-size: 11px;
    }

    .nwVwWrp .lftContainer .lftTxtContainer {
        flex-wrap: wrap;
    }
    .nwVwWrp .lftContainer .noRsrvTxt {
        margin-bottom: 10px;
    }
    .nwVwWrp .otrWrapper .rightContainer .btn-combo-list {
        flex-wrap: wrap;
    }
    .productViewbox .productCard .productInfo .titBox .essentialBox .essGraybox.vdoBox ul {
        grid-template-columns: 1fr 1fr;
    }
}
