.PlanCard {
    width: 355px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 60px 22px;
    text-align: center;
}
.planName {
    font-size: 28px;
    font-weight: 500;
    color: var(--progressLine);
    margin: 0px;
    padding-bottom: 40px;
}
.planPrice {
    font-size: 38px;
    font-weight: 600;
    color: var(--progressLine);
    margin: 0px;
    padding-bottom: 45px;
}
.planDecs {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColor);
    margin: 0px;
    padding-bottom: 30px;
}
.PlanCard .MuiButtonBase-root.MuiButton-root {
    width: 310px;
}
.PlanCard .MuiButton-label {
    text-transform: none;
}
