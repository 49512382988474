.post-listing {
    background-color: #eff4ff;
}

.postlisting-page-one {
    padding: 3rem 0;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}
.paper-content-area {
    padding: 3rem 4rem;
}
.product-post-next {
    max-width: 170px;
    width: 100%;
    margin: 0 auto;
}
.post-list-heading {
    margin: 1rem 0;
    color: var(--blueColor);
    font-family: var(--larkenMedium);
    margin-top: 50px;
}
.progressBar {
    display: flex;
    justify-content: center;
}
.firstline::after {
    content: '';
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-left: 10px;
    border-top: 1px solid black;
}

.md-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
}
.md-stepper-horizontal .md-step {
    display: table-cell;
    position: relative;
    padding: 24px;
}
.not-active-line {
    background-color: white;
    border: 1px solid var(--progressLine);
    border-radius: 50%;
    color: var(--progressLine);
}

.active-line {
    background-color: var(--progressLine);
    color: white;
    border-radius: 50%;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
    font-family: 'FontAwesome';
    font-weight: 100;
    content: '\f00c';
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
    display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
    font-family: 'FontAwesome';
    font-weight: 100;
    content: '\f040';
}
.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
    text-align: center;
    color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step.circle .md-step-title {
    font-weight: 600;
}
.progress-title {
    color: var(--progressLine) !important;
}
.md-stepper-horizontal .md-step .md-step-optional {
    font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
    color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid var(--progressLine);
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
}

/* responsive css */

@media(max-width:767px) {
    .postlisting-page-one {
        padding:0;
    }
}

@media (max-width: 500px) {
    .paper-content-area {
        padding: 2rem 1rem;
    }
}
@media (max-width: 375px) {
    .row.product-list-page-two-row .col-6,
    .row.product-fist-half .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
