.subContainer {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #fff;
}
.SubTitle {
    font-size: 28px;
    font-weight: 600;
    color: var(--progressLine);
    padding-bottom: 35px;
}
