.product-list {
    max-width: 100%;
    text-align: left;
    padding: 20px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    position: relative;
    display: flex;
    align-items: flex-start;
}

.product-list .dscTextWrap {
    height: 10ex;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-weight: normal;
}

.product-list .prdt-img-list-view .product-img-list {
    max-height: 210px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prdt-img-list-view {
    max-width: 275px;
}

.ending-list-view .product-list .prdt-img-list-view {
    height: 210px;
    max-width: 210px;
    width: 100%;
    border-radius: 0 !important;
    overflow: hidden;
}

.ending-list-view .product-list .prdt-img-list-view .favoriteCheck {
    position: absolute;
    top: 30px;
    left: 30px;
}

.product-list .prdt-list-dtls {
    margin: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.bid-title,
.bid-time-left,
.bid-desc {
    font-size: 14px;
    text-transform: uppercase;
}
.product-price .prod-title p {
    font-size: 1.5rem;
    font-family: var(--larkenRegular);
}

.prdt-list-dtls .lstTimerCntnr {
    text-transform: uppercase;
    font-size: 14px;
}

@media (max-width: 767px) {
    .product-list .prdt-img-list-view .product-img-list {
        max-width: 300px;
    }
}
