.pp-uimg {
    position: relative;
    width: 92px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.img-del {
    padding: 2px;
    position: absolute;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    right: -30px;
    top: -12px;
    cursor: pointer;
    text-align: center;
}

.pro-img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;
}
.thumbsContainer {
    display: flex;
    margin: 15px 0;
}

/* .thumbsContainer .thumb {
    margin-inline-end: 15px;
} */

.thumbsContainer .thumb img {
    height: 100px !important;
    width: 100px !important;
    border-radius: 5px;
    object-fit: cover;
    border: 1px solid #e3e3e3;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt {
    position: relative;
}

.thumbsContainer .thumb .thumbCnt .fileActions {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 5px;
    background: #5d5d5dc9;
    width: 100%;
    height: 100%;
    transition: 200ms all ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbsContainer .thumb .thumbCnt:hover .fileActions {
    visibility: visible;
    opacity: 1;
}

.thumbsContainer .thumb .thumbCnt .cancelBtn {
    margin-inline-end: 10px;
    cursor: pointer;
    color: #fff;
}

.thumbsContainer .thumb .thumbCnt .viewBtn {
    cursor: pointer;
    color: #fff;
}

/* 
.thumbsContainer .thumb .thumbCnt .cancelBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #5d5d5d;
    cursor: pointer;
    transition: 200ms all ease-in-out;
} */

.thumbsContainer .thumb .thumbCnt .cancelBtn:hover {
    color: #ffa87a;
}

.thumbsContainer .thumb .thumbCnt .viewBtn:hover {
    color: #8dd7ff;
}

.notAvailable {
    color: #b7b7b7 !important;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.thumbsContainer .thumb .thumbCnt .defaultThumb .material-icons {
    font-size: 60px;
    color: #ccc;
}

.compression-box {
    width: 150px;
    height: 150px;
    line-height: 150px;
    border: 0.5px solid black;
    border-radius: 5px;
    text-align: center;
}
