.socialIconGrp {
    display: grid;
    grid-template-columns: 35px 35px 35px 35px 35px;
    grid-gap: 5px;
    justify-content: flex-end;
    /* margin-top: 15px; */
}

.socialIconGrp a object {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    margin: auto;
    pointer-events: none;
}

.socialIconGrp a {
    border-radius: 3px;
    height: 35px;
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
}

.socialIconGrp a:hover {
    text-decoration: none;
}

.socialIconGrp a:nth-child(1) {
    background: #3c5897;
}

.socialIconGrp a:nth-child(2) {
    background: #ff5701;
}

.socialIconGrp a:nth-child(3) {
    background: #0aabed;
}

.socialIconGrp a:nth-child(4) {
    background: #000000;
}
