.forgot-password {
    max-width: 600px;
    margin: 0 auto;
}
.forgot-container-area {
    background-color: var(--blueColor);
    padding: 2rem 3rem;
}
.forgot-password-button {
    display: flex;
    justify-content: space-evenly;
}
.back-to-login {
    margin: 0.8rem 0;
}
.back-to-login:hover {
    text-decoration: underline;
}
.forgot-password-button .primButton .MuiButtonBase-root {
    text-transform: capitalize;
}

/***************** Responive Design *********************/

@media (max-width: 800px) {
    .welcome-header-logo,
    .forgot-password .welcome-heading {
        text-align: center;
    }
    .sign-up-header .forgot-password .forgot-container-area h4 {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .sign-up-header .forgot-password .forgot-container-area h4 {
        font-size: 20px;
    }
    .sign-up-header .forgot-password h5 {
        text-align: center;
    }
}
@media (max-width: 600px) {
    .forgot-container-area {
        padding: 2rem;
    }
    .forgot-password-button {
        display: flex;
        flex-direction: column-reverse;
    }
    .sign-up-header .forgot-password .forgot-container-area h4,
    .sign-up-header .forgot-password h5 {
        font-size: 20px;
    }
    .forgot-container-area .forgot-password-content .forgot-password-button .back-to-login span {
        font-size: 15px;
    }
    .forgot-container-area .forgot-password-content .customInput .login-user-name-label {
        font-size: 15px;
    }
    .forgot-container-area
        .forgot-password-content
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 16px;
    }
}
@media (max-width: 500px) {
    .forgot-container-area {
        padding: 2rem;
    }
    .forgot-password-button {
        display: flex;
        flex-direction: column-reverse;
    }
    .forgot-password .forgot-container-area h4 {
        font-size: 18px;
    }
    .forgot-container-area .forgot-password-content .forgot-password-button .back-to-login span {
        font-size: 15px;
    }
    .sign-up-header .forgot-password h5 {
        font-size: 19px;
    }
    .forgot-container-area
        .forgot-password-content
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 15px;
    }
}
@media (max-width: 445px) {
    .forgot-container-area {
        padding: 2rem;
    }
    .forgot-password-button {
        display: flex;
        flex-direction: column-reverse;
    }
    .forgot-password .forgot-container-area h4 {
        font-size: 17px;
    }
    .forgot-container-area .forgot-password-content .forgot-password-button .back-to-login span {
        font-size: 14px;
    }
    .forgot-container-area
        .forgot-password-content
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .sign-up-header .forgot-password h5 {
        font-size: 17px;
    }
}

@media (max-width: 340px) {
    .forgot-password-button {
        display: flex;
        flex-direction: column-reverse;
    }
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        font-size: 12px;
    }
    .forgot-container-area
        .forgot-password-content
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .forgot-password-content .customInput .login-user-name-label {
        font-size: 14px;
    }
    .forgot-password .forgot-container-area h4 {
        font-size: 16px;
    }
    .forgot-container-area .forgot-password-content .forgot-password-button .back-to-login span {
        font-size: 14px;
    }
    .forgot-container-area {
        padding: 2rem;
    }
}
@media (max-width: 320px) {
    .forgot-password-button {
        display: flex;
        flex-direction: column-reverse;
    }
    .forgot-container-area h4 {
        font-size: 16px;
    }
    .forgot-container-area {
        background-color: var(--blueColor);
        padding: 2rem;
    }
    .forgot-container-area
        .forgot-password-content
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .forgot-container-area .forgot-password-content .customInput .login-user-name-label {
        font-size: 14px;
    }
    .forgot-container-area .forgot-password-content .forgot-password-button .back-to-login span {
        font-size: 14px;
    }
}
