:root {
    --circularBook: 'circular-book';
    --circularBookItalic: 'circular-book-italic';
    --circularMedium: 'circular-medium';
    --circularMediumItalic: 'circular-medium-italic';
    --circularBold: 'circular-bold';
    --circularBoldItalic: 'circular-bold-italic';
    --circularBlack: 'circular-black';
    --circularBlackItalic: 'circular-black-italic';

    --larkenThin: 'larken-thin';
    --larkenThinItalic: 'larken-thin-italic';
    --larkenRegular: 'larken-regular';
    --larkenItalic: 'larken-italic';
    --larkenLight: 'larken-light';
    --larkenLightItalic: 'larken-light-italic';
    --larkenMedium: 'larken-medium';
    --larkenMediumItalic: 'larken-medium-italic';
    --larkenBold: 'larken-bold';
    --larkenBoldItalic: 'larken-bold-italic';
    --larkenBlack: 'larken-black';
    --larkenBlackItalic: 'larken-black-italic';
    --larkenExtraBold: 'larken-extrabold';
    --larkenExtraItalic: 'larken-extrabold-italic';

    --primColor: #a48843;
    --secColor: rgb(43, 43, 43);
    --accentColor: #969696;
    --backgroundColor: #f5f5f5;
    --blueColor: #173e80;
    --lightWhite: #ffffff;
    --lightGrey: #f4f4f4;
    --textColor: #333333;
    --grayLine: #afafaf;
    --lightGreen: #364d42;
    --progressLine: #233e7c;
    --lightBlueBackground: #eff4ff;
}

.whiteBg {
    background: var(--lightWhite);
}

.err {
    text-align: left;
    color: #ff2f2f;
}
.primButton button,
.secButton button {
    height: 50px;
}

.primButton.small button,
.secButton.small button {
    height: 40px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #4e4e4e;
    background: #ffddd6;
}

::selection {
    color: #4e4e4e;
    background: #ffddd6;
}

@media (max-width: 500px) {
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        height: 45px;
        font-size: 13px;
    }
}
.light-white {
    color: var(--lightWhite) !important;
}
