.reset-passwrd {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}
.reset-passwrd-container-area {
    background-color: var(--blueColor);
    padding: 2rem 3rem;
}
.reset-passwrd-btn-area .primButton {
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
}
.reset-passwrd-btn-area .primButton .MuiButtonBase-root {
    text-transform: capitalize;
}
.welcome-heading {
    text-align: left;
    font-family: var(--larkenRegular);
}
.sign-up-header h4 {
    font-size: 30px;
}

/***************** Responive Design *********************/

@media (max-width: 800px) {
    .sign-up-logo .logo-img {
        max-width: 280px;
        width: 100%;
    }
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 20px;
    }
    .welcome-header-logo {
        text-align: center;
    }
    .sign-up-logo .logo-img {
        max-width: 280px;
        width: 100%;
    }
    .sign-up-header {
        padding: 0 3rem;
    }
}

@media (max-width: 600px) {
    .sign-up-logo .logo-img {
        max-width: 280px;
        width: 100%;
    }
    .welcome-header-logo {
        text-align: center;
    }
    .welcome-heading {
        text-align: center;
    }
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 19px;
    }
    .reset-passwrd-btn-area .primButton {
        max-width: 330px;
        width: 100%;
    }
    .reset-passwrd-btn-area .primButton {
        max-width: 400px;
        width: 100%;
    }
    .reset-passwrd-container-area {
        padding: 2rem;
    }
}

@media (max-width: 500px) {
    .welcome-header-logo {
        text-align: center;
    }
    .sign-up-logo .logo-img {
        max-width: 260px;
        width: 100%;
    }
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 18px;
    }
    .reset-passwrd-content .login-user-name-label {
        font-size: 14px;
    }
    .reset-passwrd-content
        .reset-new-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder,
    .reset-passwrd-content
        .reset-cnfrm-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .sign-up-header {
        padding: 0px 3rem;
    }
    .reset-passwrd-btn-area .primButton {
        max-width: 300px;
        width: 100%;
    }
    .reset-passwrd .welcome-heading {
        text-align: center;
    }
    .reset-passwrd-container-area {
        padding: 2rem;
    }
    .sign-up-header {
        padding: 0 2rem;
    }
}

@media (max-width: 445px) {
    .sign-up-header {
        padding: 0px 2rem;
    }
}

@media (max-width: 414px) {
    .sign-up-logo .logo-img {
        max-width: 230px;
        width: 100%;
    }
    .sign-up-header {
        padding: 0px 2rem;
    }
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        font-size: 13px;
    }
}

@media (max-width: 340px) {
    .sign-up-logo .logo-img {
        max-width: 220px;
        width: 100%;
    }
    .sign-up-header {
        padding: 0px 1.5rem;
    }
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 16px;
    }
    .reset-passwrd-content .login-user-name-label {
        font-size: 13px;
    }
    .reset-passwrd-content
        .reset-new-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder,
    .reset-passwrd-content
        .reset-cnfrm-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 12px;
    }
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        height: 45px;
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    .sign-up-logo .logo-img {
        max-width: 200px;
        width: 100%;
    }
    .sign-up-header {
        padding: 0 1rem;
    }
    .reset-passwrd-container-area h4,
    .reset-passwrd h5 {
        font-size: 16px;
    }
    .reset-passwrd-content
        .reset-new-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder,
    .reset-passwrd-content
        .reset-cnfrm-passwrd
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 12px;
    }
    .reset-passwrd-content .login-user-name-label {
        font-size: 13px;
    }
}
