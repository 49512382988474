.dashboardLayout {
    display: flex;
    justify-content: space-between;
    background: #fff;
}

.dashboardLayout .dashboardLt {
    max-width: 20%;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 60px 30px;
}

.dashboardLayout .dashboardLt h4 {
    font-size: 28px;
    color: var(--blueColor);
    margin-bottom: 30px;
    font-family: var(--larkenBold);
}

.dashboardLayout .dashboardRt {
    max-width: 78%;
    width: 100%;
    min-height: 100%;
    background: #eff4ff;
    height: auto;
}

.dashboardLayout .dashboardLt ul {
    padding: 0;
    margin: 0;
}

.dashboardLayout .dashboardLt ul > .MuiListItem-button {
    padding: 0;
}

.dashboardLayout .dashboardLt ul .dashboardNav {
    color: var(--secColor);
    padding: 15px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: var(--larkenMedium);
}

.dashboardLayout .dashboardLt ul .dashboardNav:hover {
    text-decoration: none;
}

.dashboardLayout .dashboardLt ul .dashboardNav.active {
    color: var(--blueColor);
    background: #173e801f;
    border-left: 2px solid var(--blueColor);
    border-right: 2px solid var(--blueColor);
}

/* responsive css */

@media (max-width: 900px) {
    .dashboardLayout .dashboardLt {
        display: none;
    }
    .dashboardLayout .dashboardRt {
        max-width: 100%;
    }
    .dashSwipeableDrawer .MuiButton-label .MuiSvgIcon-root {
        font-size: 1.8rem;
    }
    .dashSwipeableDrawer {
        position: absolute;
        left: 15px;
        top: 27px;
        z-index: 99;
    }
    .dashSwipeableDrawer .MuiButton-text {
        padding: 5px 8px;
    }
    .dashSwipeableDrawer .MuiButton-label {
        background: #173e80;
        min-width: auto !important;
        width: auto;
        height: auto;
        padding: 9.5px 10px;
        color: #fff;
        border-radius: 0px;
    }
    .buyer-my-auction {
        max-width: 575px !important;
        margin: 15px 0 0 15px;
        padding-left: 72px;
        padding-right: 15px;
    }
    .commonDashNavSlider ul .MuiListItem-button {
        padding: 0;
    }
    .commonDashNavSlider ul .MuiListItem-button a .MuiSvgIcon-root {
        margin-right: 15px;
    }
    .commonDashNavSlider ul .MuiListItem-button a {
        width: 100%;
        padding: 10px 16px;
        align-items: center;
        display: flex;
    }
    .commonDashNavSlider h4 {
        padding-left: 16px;
        padding-right: 16px;
        background: #0b1f4029;
        padding: 12px 16px;
        font-size: 18px;
        margin: 0;
    }
    .SideLogo img {
        max-width: 160px;
    }
    .SideLogo .MuiButtonBase-root {
        justify-content: flex-end;
    }
    .SideLogo {
        background: #0b1f40;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        justify-content: center;
    }
    .commonDashNavSlider ul .MuiListItem-button a.active {
        background: #d8dbe0;
        border-right: 4px solid #0b1f40;
    }
    .commonDashNavSlider ul .MuiListItem-button a.active .MuiListItemIcon-root {
        color: #0b1f40;
    }
}
@media (max-width: 600px) {
    .dashSwipeableDrawer {
        left: 10px;
        top: 12px;
    }
    .dashSwipeableDrawer .MuiButton-text {
        padding: 3px 0;
        min-width: 58px;
    }
}
