.paymentCard {
    width: auto;
    height: 180px;
    border-radius: 15px;
    padding: 20px;
    background: transparent linear-gradient(239deg, #726e9e 0%, #042843 100%) 0% 0% no-repeat
        padding-box;
    max-width: 324px;
    margin: 0 auto;
}

.paymentCard.disabled {
    background: transparent linear-gradient(239deg, #a48843 0%, #846008 100%) 0% 0% no-repeat
        padding-box;
    filter: grayscale();
}

.paymentCard .cardType {
    text-align: right;
}

.paymentCard .cardType .card-type-dync {
    width: 35px;
}
.paymentCard .cardChip {
    margin: 15px 0;
}

.paymentCard label {
    font-size: 12px;
    color: #fff;
}

.paymentCard label span {
    padding-right: 10px;
}

.paymentCard h6 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 5px;
}

.paymentCard .cardChip {
    text-align: left;
}

.card-wrapper .row.pt-3 {
    margin: 0 -5px;
}

.card-wrapper .row.pt-3 > div {
    padding: 0 5px;
}
