.breadCrum {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.productViewbox .MuiBreadcrumbs-root {
    padding-top: 50px;
    padding-bottom: 25px;
}
.productViewbox .MuiBreadcrumbs-root li a {
    color: var(--blueColor);
    font-size: 16px;
}
.productViewbox .MuiBreadcrumbs-separator {
    margin-left: 0px;
}
