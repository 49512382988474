.emailverification {
    background-color: var(--blueColor);
    height: 100vh;
}
.verify-note {
    color: white;
    position: absolute;
    top: 50%;
    width: 100%;
}
.emailverification img {
    width: 350px;
    margin: 2rem 0;
}
