.input-boxes {
    text-align: left;
}
.registration-submit .primButton {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
}
.desc-postlist .MuiFormControl-root {
    width: 100%;
}
.ql-editor {
    height: 100px !important;
    overflow-y: scroll !important;
}
