.header {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    padding: 0;
    margin: 0;
    z-index: 10;
}
.header .headerLink {
    color: #fff;
}

.header .headerLink:not(:last-child) {
}
.header .headerLink:not(:last-child) {
    margin-right: 5px;
}

.header .headerLink:nth-child(2) {
    margin-left: 5px;
}

.header .headerLogo {
    max-width: 400px;
    width: 100%;
    margin: 0 60px;
}

.header > .customContainer button {
    min-width: 150px;
    width: auto;
}

.header > .customContainer {
    height: 100%;
}

.header-menu {
    text-transform: capitalize !important;
}

.usreNameelli {
    width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 5px;
}

a.headerLink {
    color: #7b7b7b;
    text-decoration: none;
}

.angerTag {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 16px;
    color: #fff;
}
.cg-fname {
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    padding: 0 5px;
}

/* responsive css */

@media (max-width: 1199px) {
    .header .headerLogo {
        max-width: 250px;
        width: 100%;
        margin: 0 35px;
    }

    .header.innerHeaderbg,
    .header {
        max-height: 80px;
    }
}

@media (max-width: 992px) {
    .header .headerLogo {
        margin: 0 15px;
        max-width: 220px;
    }

    .header .headerLogo {
        margin: 0 10px;
    }
}

@media (max-width: 767px) {
    .header {
        height: auto;
    }

    .commonDashNavSlider ul .MuiListItem-button a.sideNavBtn,
    .commonDashNavSlider ul .MuiListItem-button .sideNavBtn {
        background: #0b1f40;
        margin: 15px 18px;
        border-radius: 4px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        text-transform: capitalize;
        border: 1px solid #0b1f40;
        width: 100%;
    }
    .responsableHeader .MuiButtonBase-root {
        justify-content: flex-end;
        padding: 5px;
        margin: 5px 0;
        min-width: auto;
    }
    .header > .customContainer {
        display: none !important;
    }
    .responsableHeader .headerLogo {
        max-width: 230px;
        margin: 0px;
        outline: 0;
    }
    .responsableHeader {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-top: 7px;
        padding-right: 15px;
        align-items: center;
        position: sticky;
        background: #364d421f;
        padding-bottom: 7px;
        top: 0;
    }
    .responsableHeader button {
        color: #fff;
    }
    .header.white {
        position: sticky !important;
        background: #173e80;
        top: 0;
        z-index: 999;
        transition: all 0.1s ease-in;
    }
}
@media (max-width: 500px) {
    .responsableHeader .headerLogo {
        max-width: 180px;
    }
}
