.add-address-license {
    font-size: 10px;
    margin: 0;
}
.sign-in-heading {
    display: flex;
    justify-content: space-between;
}

.first-half {
    width: 100%;
    max-width: 535px;
    margin: 0 3rem;
}
.second-half {
    width: 100%;
    max-width: 648px;
    margin: 0 3rem;
}
.signup-state .input-login .MuiInputBase-root .MuiSelect-root,
.country-signup .input-login .MuiInputBase-root .MuiSelect-root {
    color: whitesmoke;
}
.fileupload-signup {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: 0;
}

.upldWrapper {
    border: 1px dashed var(--primColor);
    width: 100%;
    padding: 40px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.upldWrapper label {
    color: white;
    font-size: 0.9rem;
}

.upldWrapper .custom-upload {
    background-color: white;
    color: black;
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid #707070;
}

.upldWrapper .custom-upload label {
    color: black;
    font-size: 0.8rem;
    margin: 0;
}
.custom-upload {
    overflow: hidden;
}
.upldWrapper {
    background-color: #183c77;
}
.note-content p {
    font-size: 13px;
}
.sign-up-confirmation
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiButtonBase-root
    .MuiIconButton-label,
.sign-up-confirmation .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root {
    color: var(--lightWhite);
}
.sign-up-confirm-btn .confirm-btn .MuiButtonBase-root {
    background-color: var(--primColor);
    border: var(--primColor);
    text-transform: capitalize;
    font-size: 17px;
}
.sign-up-confirm-btn {
    max-width: 210px;
    width: 100%;
    float: right;
}
.sign-up-credential {
    background-color: var(--blueColor);
    padding: 30px 50px 0 50px;
}
.upldWrapper p {
    color: white;
    padding-right: 10px;
    margin-bottom: 0;
}
.upldWrapper .browse-upload {
    background-color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
}
.country-signup .MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup,
.signup-state .MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {
    background-color: var(--blueColor);
}
/***************** Responive Design *********************/
@media (max-width: 1200px) {
    .first-half,
    .second-half {
        width: 100%;
        max-width: 100%;
        margin: 0 1rem;
    }
    .sign-up-credential {
        padding: 30px 30px 0 30px;
    }
}
@media (max-width: 1024px) {
    .sign-in-heading {
        flex-wrap: wrap;
    }
}
@media (max-width: 992px) {
    .sign-in-heading {
        flex-wrap: wrap;
    }
    .welcome-header-logo {
        text-align: center;
    }
    .welcome-heading {
        text-align: center;
    }
    .sign-up-header {
        padding: 0 4rem;
    }
    .sign-in-heading .first-half,
    .sign-in-heading .second-half {
        margin: 0 2rem;
    }
    .sign-up-credential {
        padding: 30px;
    }
}
@media (max-width: 800px) {
    .sign-in-heading {
        flex-wrap: wrap;
    }
    .sign-up-credential {
        padding: 20px;
    }
    .first-half,
    .second-half {
        margin: 0 2rem;
    }
    .sign-up-header {
        padding: 0 4rem;
    }
    .welcome-heading {
        text-align: center;
    }
}
@media (max-width: 768px) {
}
@media (max-width: 600px) {
}
@media (max-width: 500px) {
    .sign-up-confirmation
        .MuiFormGroup-root
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        font-size: 12px;
        padding-left: 0;
        padding-right: 0;
    }
    .first-half,
    .second-half {
        margin: 0 1rem;
    }
    .sign-up-header {
        padding: 0px 3rem;
    }
    .upldWrapper .browse-upload {
        padding: 0.5rem;
        font-size: 12px;
    }
    .sign-up-confirmation
        .MuiFormGroup-root
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        font-size: 14px;
    }
    .first-half-fields .customInput,
    .second-half .customInput {
        margin-bottom: 15px;
    }
    .bill-radio-btn
        .MuiFormGroup-root
        .login-radio-btn
        .MuiButtonBase-root
        .MuiIconButton-label
        .PrivateRadioButtonIcon-root-11
        .MuiSvgIcon-root {
        font-size: 1.3rem;
    }
    .bill-radio-btn .MuiFormGroup-root .login-radio-btn .MuiTypography-root {
        font-size: 0.9rem;
    }
    .first-half h5,
    .second-half h5 {
        font-size: 1.3rem;
    }

    .first-half .input-login p,
    .second-half .input-login p {
        font-size: 14px;
    }
}
@media (max-width: 445px) {
    .sign-up-header {
        padding: 0px 2rem;
    }
    .sign-in-heading .first-half,
    .sign-in-heading .second-half {
        margin: 0;
    }
    .bill-radio-btn
        .MuiFormGroup-root
        .login-radio-btn
        .MuiButtonBase-root
        .MuiIconButton-label
        .PrivateRadioButtonIcon-root-11
        .MuiSvgIcon-root {
        font-size: 1.2rem;
    }

    .second-half .first-half-fields .custom-upload .upldWrapper {
        padding: 20px;
        display: block;
    }
    .second-half .first-half-fields .custom-upload .upldWrapper p {
        font-size: 13px;
        margin-bottom: 0.8rem;
    }
    .second-half .first-half-fields .sign-up-confirm-btn {
        width: 150px;
        margin: 0 auto;
        float: none;
    }
    .sign-up-confirm-btn .confirm-btn .MuiButtonBase-root {
        font-size: 13px;
    }
}
@media (max-width: 340px) {
    .sign-up-header {
        padding: 0px 1.5rem;
    }
    .sign-in-heading .first-half,
    .sign-in-heading .second-half {
        margin: 0;
    }
    .sign-up-confirm-btn .primButton {
        width: 150px;
        margin: 0 auto;
    }
    .sign-up-confirm-btn .confirm-btn .MuiButtonBase-root {
        font-size: 13px;
    }
    .bill-radio-btn
        .MuiFormGroup-root
        .login-radio-btn
        .MuiButtonBase-root
        .MuiIconButton-label
        .PrivateRadioButtonIcon-root-11
        .MuiSvgIcon-root {
        font-size: 1.2rem;
    }

    .second-half .first-half-fields .custom-upload .upldWrapper {
        padding: 20px;
        display: block;
    }
    .second-half .first-half-fields .custom-upload .upldWrapper p {
        font-size: 13px;
        margin-bottom: 0.8rem;
    }
    .sign-up-header h4 {
        font-size: 20px;
        text-align: left;
    }
    .first-half h5,
    .second-half h5 {
        font-size: 1rem;
    }
}
@media (max-width: 320px) {
    .sign-up-header {
        padding: 0px 1.5rem;
    }

    .sign-in-heading .first-half,
    .sign-in-heading .second-half {
        margin: 0;
    }
    .sign-up-header h4 {
        font-size: 18px;
        text-align: left;
    }
    .sign-up-credential {
        padding: 25px;
    }
    .first-half h5,
    .second-half h5 {
        font-size: 1.1rem;
    }
    .bill-radio-btn .MuiFormGroup-root .login-radio-btn .MuiTypography-root {
        font-size: 0.8rem;
    }
    .bill-radio-btn
        .MuiFormGroup-root
        .login-radio-btn
        .MuiButtonBase-root
        .MuiIconButton-label
        .PrivateRadioButtonIcon-root-11
        .MuiSvgIcon-root {
        font-size: 1.2rem;
    }

    .second-half .first-half-fields .custom-upload .upldWrapper {
        padding: 20px;
        display: block;
    }
    .second-half .first-half-fields .custom-upload .upldWrapper p {
        font-size: 13px;
        margin-bottom: 0.8rem;
    }
    .sign-up-confirmation
        .MuiFormGroup-root
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        font-size: 13px;
        padding: 0 9px;
    }
    .sign-up-confirm-btn .confirm-btn .MuiButtonBase-root {
        font-size: 13px;
    }
    .sign-up-confirm-btn .primButton {
        width: 150px;
        margin: 0 auto;
    }
}

/********************************************************/
