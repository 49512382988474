.past-auction h3 {
    text-align: left;
    margin: 2rem 0;
}

.live-auction-productGrid {
    padding-bottom: 3rem;
}

.past-auction {
	min-height:calc(100vh - 450px)
}

@media(max-width:1199px) {
	.past-auction {
		min-height:calc(100vh - 375px)
	}
}