.checkout .prdt-dtls {
    background-color: var(--lightWhite);
}

.checkout .pymt-dtls {
    background-color: var(--lightBlueBackground);
}

.checkout .pymt-dtls,
.checkout .prdt-dtls {
    max-width: 835px;
    width: 100%;
    padding: 2rem 3rem;
}

.checkout {
    display: flex;
}

.prdt-info .prod-info-img img {
    /* max-width: 150px; */
    width: 100%;
    height: 115px;
    border-radius: 10px;
}

.product-list .prdt-info {
    display: flex;
    /* justify-content: space-between; */
}

.prdt-info .prod-name,
.prdt-info .prod-price,
.prdt-info .prod-delete {
    text-align: left;
    padding: 3rem 0;
}

.prdt-info .piLt {
    /* width: 80%; */
    justify-content: space-between;
}

.shpng-address-row .input-left,
.pymt-dtls-row .input-left,
.shpng-address h5,
.pymt-dtls h5 {
    text-align: left;
}

.shpng-address h5,
.pymt-dtls h5 {
    text-align: left;
    font-family: var(--larkenMedium);
}

.prdt-info .prod-name p,
.prdt-info .prod-price p,
.prdt-info .prod-delete p {
    margin-bottom: 0;
}

.prdt-info .prod-delete {
    margin: 0.5rem 0;
}

.prdt-info .prod-delete .delete-icon {
    color: #ff3b3b;
    font-size: 2rem;
}
.save-address-confrm .MuiFormGroup-root {
    justify-content: flex-end;
}
.shpng-address-row .input-left .customSelect p,
.shpng-address-row .input-left .customInput p,
.pymt-dtls-row .input-left .customSelect p,
.pymt-dtls-row .input-left .customInput p {
    color: var(--progressLine);
}

.shpng-address-row
    .input-left
    .customSelect
    .MuiFormControl-root
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline,
.shpng-address-row
    .input-left
    .customInput
    .MuiFormControl-root
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline,
.pymt-dtls-row
    .input-left
    .customSelect
    .MuiFormControl-root
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline,
.pymt-dtls-row
    .input-left
    .customInput
    .MuiFormControl-root
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor);
}

.pymt-dtls-row .mode-pymt .customSelect {
    margin-bottom: 10px;
}

.pymt-dtls-row .mode-pymt {
    margin-bottom: 20px;
}

.pymt-dtls-row .mode-pymt span {
    text-decoration: underline;
    color: var(--primColor);
}

.pymt-dtls-row .mode-pymt span:hover {
    cursor: pointer;
}

.shpng-address h5,
.pymt-dtls h5 {
    margin: 1rem 0;
    font-size: 26px;
}

.pymt-dtls .paymt-subt {
    margin: 0 auto;
}

.pymt-dtls .paymt-subt .primButton .MuiButtonBase-root {
    text-transform: capitalize;
}

.paymentCardPopup .paymentCard {
    margin: 0 auto;
}
.product-list .totl-pymt {
    text-align: left;
    font-size: 20px;
}

.paymentCardPopup .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root {
    border-bottom: 1px solid #707070;
}
.paymentCardPopup .MuiDialog-container .MuiPaper-root .MuiDialogContent-root .primButton {
    max-width: 210px;
    width: 100%;
    margin: 2rem auto;
}
.paymentCardPopup
    .MuiDialog-container
    .MuiPaper-root
    .MuiDialogContent-root
    .primButton
    .MuiButtonBase-root {
    text-transform: capitalize;
}
.save-address-confrm .MuiFormGroup-root .MuiFormControlLabel-root {
    margin: 0;
}
.save-address-confrm
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiTypography-root
    .MuiButtonBase-root {
    padding: 0;
}
.pymt-dtls .paymt-subt .primButton {
    width: 110px;
}
.prdt-info .prod-info-img,
.prdt-info .prod-name,
.prdt-info .prod-price,
.prdt-info .prod-delete {
    margin-right: 5px;
}

/* responsive css */

@media (max-width: 992px) {
    .checkout .pymt-dtls,
    .checkout .prdt-dtls {
        padding: 2rem 1rem;
    }

    .checkout {
        flex-direction: column;
    }
    .checkout .pymt-dtls,
    .checkout .prdt-dtls {
        padding: 2rem 4rem;
    }
}

@media (max-width: 475px) {
    .checkout .pymt-dtls,
    .checkout .prdt-dtls {
        padding: 2rem 1rem;
    }
}
