.login-content-area {
    background-color: var(--blueColor);
    /* height: 100vh;
    overflow: hidden; */
    padding: 3rem;
}

.mobile-signup {
    min-height: 100vh;
}

.login-content-area {
    margin: 0px;
}

.login-confirmation .MuiFormControlLabel-root {
    margin: 0;
}
.login-sign-in {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
}

.login-sign-in:hover {
    cursor: pointer;
}

.input-login .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--primColor);
}
.terms,
.conditions {
    color: var(--primColor);
}

.login-confirmation .MuiFormGroup-root .MuiFormControlLabel-root .MuiCheckbox-root {
    color: var(--primColor);
}
.input-login input {
    color: #fff;
}
.login-confirm-button .primButton .MuiButtonBase-root {
    text-transform: capitalize;
    background-color: var(--primColor) !important;
    border-color: var(--primColor) !important;
}
.login-radio-btn .MuiButtonBase-root .MuiIconButton-label .PrivateSwitchBase-input-6 {
    color: var(--primColor);
}
.login-car-image {
    background-image: url('../../assets/images/2.jpeg');
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.garvel-logo {
    text-align: right;
}
.car-image-content {
    margin-top: 23%;
}
.login-car-image .garvel-logo .garvel-logo-image {
    margin: 1.5rem;
}

.login-confirmation {
    display: flex;
    justify-content: space-between;
}
/*************************Mobile Responsive*****************************/

@media (max-width: 992px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-content-area {
        max-width: 90%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 300px;
        width: 100%;
    }

    .login-car-image .garvel-logo {
        text-align: center;
    }
    .car-image-content {
        margin-top: 0;
    }
}

@media (max-width: 800px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-content-area {
        max-width: 90%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 300px;
        width: 100%;
    }

    .login-car-image .garvel-logo {
        text-align: center;
    }
    .car-image-content {
        margin-top: 0;
    }
    .login-confirmation .login-confirm-button .primButton {
        margin-top: 1rem;
    }

    .car-image-content {
        margin-top: 0;
    }
}
@media (max-width: 768px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-content-area {
        max-width: 88%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 300px;
        width: 100%;
    }
    .login-confirmation {
        display: block;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .car-image-content {
        margin-top: 0;
    }
    .login-confirmation .login-confirm-button .primButton {
        margin-top: 1rem;
    }
}
@media (max-width: 600px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-content-area {
        max-width: 88%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 300px;
        width: 100%;
    }
    .login-confirmation {
        display: block;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .car-image-content {
        margin-top: 0;
    }
    .login-confirmation .login-confirm-button .primButton {
        margin-top: 1rem;
    }
}
@media (max-width: 500px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-content-area {
        max-width: 88%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 280px;
        width: 100%;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .car-image-content {
        margin-top: 0;
    }
    .login-car-image .car-image-content h1 {
        font-size: 1.8rem;
    }
    .login-confirmation {
        display: block;
    }
    .login-confirmation .login-confirm-button .primButton {
        margin-top: 1rem;
    }
}
@media (max-width: 445px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 180px;
        width: 100%;
    }
    .login-car-image .car-image-content {
        margin-top: 0;
    }
    .login-car-image .car-image-content h1 {
        font-size: 18px;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .login-content-area {
        max-width: 85%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-confirmation {
        display: block;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 220px;
        width: 100%;
    }
}
@media (max-width: 340px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
        height: 27vh;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 180px;
        width: 100%;
    }
    .login-car-image .car-image-content {
        margin-top: 0;
    }
    .login-car-image .car-image-content h1 {
        font-size: 18px;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .login-content-area {
        max-width: 85%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-content-area {
        padding: 2rem;
    }
    .login-confirmation {
        display: block;
    }
    .signup-area-content .sign-up-content .signup-content-input .customInput {
        margin-bottom: 15px;
    }
}
@media (max-width: 320px) {
    .mobile-signup {
        flex-wrap: wrap-reverse;
        background-image: url('../../assets/images/2.jpeg');
        /* background-image: url('../../assets/images/sign-up-background.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: scroll;
    }
    .login-car-image {
        background: none;
        height: 30vh;
    }
    .login-car-image .garvel-logo .garvel-logo-image {
        max-width: 180px;
        width: 100%;
    }
    .login-car-image .car-image-content {
        margin-top: 0;
    }
    .login-car-image .car-image-content h1 {
        font-size: 18px;
    }
    .login-car-image .garvel-logo {
        text-align: center;
    }
    .login-content-area {
        max-width: 85%;
        width: 100%;
        margin: 0 auto;
        height: auto;
        overflow: auto;
    }
    .login-confirmation {
        display: block;
    }
    .login-content-area {
        padding: 2rem;
    }
    .already-login .already-member {
        font-size: 14px;
    }
    .already-login .login-sign-in {
        font-size: 12px;
    }
    .signup-area-content h4 {
        font-size: 18px;
    }
    .signup-area-content .signup-content-input .customInput p {
        font-size: 14px;
    }
    .signup-area-content .signup-content-input .customInput {
        margin-bottom: 15px;
    }
    .signup-area-content
        .sign-up-content
        .signup-content-input
        .customInput
        .MuiFormControl-root
        .MuiInputBase-root
        .MuiInputBase-input::placeholder {
        font-size: 14px;
    }
    .agree-condition {
        font-size: 14px;
    }
}
