.product-fist-half .sale-owned p,
.product-fist-half .listed-site p,
.chng-Vehicle-area .customInput .chng-vehicle,
.video-link-vehicle .customInput .dealership-label,
.dealership .customInput .dealership-label,
.product-fist-half .is-dealer p {
    text-align: left;
}
.img-upload-wrapper {
    border: 1px dashed var(--primColor);
    width: 100%;
    padding: 40px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.nxt-page-btn {
    margin: 2rem 0;
}
.upload-area .custom-upload .img-upload-wrapper .browse-upload {
    background-color: #ffffff;
    padding: 0.5rem 1.3rem;
    border-radius: 5px;
    border: 1px solid #707070;
}
.img-upload-wrapper .custom-upload label {
    margin-bottom: 0;
}

.upldBrwsBtn {
    background-color: #ffffff;
    padding: 0.5rem 1.3rem;
    border-radius: 5px;
    border: 1px solid #707070;
}
/* responsive css */

@media (max-width: 600px) {
    .img-upload-wrapper {
        padding: 20px 10px;
        flex-direction: column;
    }

    .img-upload-wrapper .text-left {
        margin-bottom: 10px;
    }

    .img-upload-wrapper .text-left h4 {
        text-align: center !important;
    }
}
